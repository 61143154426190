// Copyright © 2024 The Things Industries B.V.

import Yup from '@ttn-lw/lib/yup'
import { id as profileIdRegexp } from '@ttn-lw/lib/regexp'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import m from './messages.tti'

const hasNoEmptyEntry = receivers =>
  receivers.every(value => value?.receiver_id !== '' && value?.receiver_id !== undefined)

const validationSchema = Yup.object().shape({
  profiles: Yup.array().of(
    Yup.object().shape({
      ids: Yup.object().shape({
        profile_id: Yup.string()
          .matches(profileIdRegexp, Yup.passValues(sharedMessages.validateIdFormat))
          .min(2, Yup.passValues(sharedMessages.validateTooShort))
          .max(36, Yup.passValues(sharedMessages.validateTooLong))
          .required(sharedMessages.validateRequired),
      }),
      is_default: Yup.bool(),
      receivers_ids: Yup.array()
        .of(
          Yup.object().shape({
            receiver_id: Yup.string(),
          }),
        )
        .min(1, m.validateReceiverAtLeastOne)
        .test('has no empty entry', m.validateEmptyReceiver, hasNoEmptyEntry),
    }),
  ),
  _default_profile: Yup.number(),
})

export default validationSchema
