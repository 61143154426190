// Copyright © 2024 The Things Industries B.V.

import React, { useCallback, useMemo } from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import Form from '@ttn-lw/components/form'
import Input from '@ttn-lw/components/input'
import Button from '@ttn-lw/components/button'
import KeyValueMap from '@ttn-lw/components/key-value-map'
import Select from '@ttn-lw/components/select'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import PropTypes from '@ttn-lw/lib/prop-types'

import { selectAlertReceiversStore } from '@console/store/selectors/alert-notification.tti'

const m = defineMessages({
  addAnotherAlertReceiver: 'Add another alert receiver',
  profileId: 'Profile ID',
  profileIdPlaceholder: 'E.g. critical-1',
  receiversRemoved:
    'This profile has currently no receivers set up since their original receivers have been removed',
  removeProfile: 'Remove this profile',
  selectAlertReceiver: 'Select alert receiver...',
})

const getReceiverType = r => r.email?.recipient || r.sms?.phone_number || r.webhook?.url || ''

const encodeReceivers = value => value.map(r => ({ receiver_id: r }))

const decodeReceivers = value => value.map(r => r.receiver_id)

const hasReceiversRemoved = value => value.length === 1 && value[0].receiver_id === ''

const AlertProfile = props => {
  const { profile, index, handleRemoveProfile } = props
  const alertReceivers = useSelector(selectAlertReceiversStore)
  const isFromState = Boolean(profile.created_at)

  const handleRemoveProfileClick = useCallback(() => {
    handleRemoveProfile(index)
  }, [handleRemoveProfile, index])

  const receiverOptions = useMemo(
    () =>
      Object.values(alertReceivers).map(r => ({
        value: r.ids.receiver_id,
        label: `${r.ids.receiver_id} (${getReceiverType(r)})`,
      })),
    [alertReceivers],
  )

  return (
    <>
      <Form.Field
        title={m.profileId}
        name={`profiles.${index}.ids.profile_id`}
        placeholder={m.profileIdPlaceholder}
        required
        component={Input}
        autoFocus
        readOnly={isFromState}
      />
      <Form.Field
        name={`profiles.${index}.receivers_ids`}
        title={sharedMessages.alertReceivers}
        valuePlaceholder={m.selectAlertReceiver}
        component={KeyValueMap}
        inputElement={Select}
        actionDisable
        indexAsKey
        addMessage={m.addAnotherAlertReceiver}
        additionalInputProps={{ options: receiverOptions }}
        distinctOptions
        atLeastOneEntry
        warning={
          isFromState && hasReceiversRemoved(profile.receivers_ids) ? m.receiversRemoved : undefined
        }
        required
        maxItems={receiverOptions.length}
        encode={encodeReceivers}
        decode={decodeReceivers}
      />
      <div className="mt-cs-l">
        <Button
          name="remove_profile"
          type="button"
          message={m.removeProfile}
          onClick={handleRemoveProfileClick}
          danger
        />
      </div>
    </>
  )
}

AlertProfile.propTypes = {
  handleRemoveProfile: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  profile: PropTypes.shape({
    ids: PropTypes.shape({
      profile_id: PropTypes.string,
    }),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    is_default: PropTypes.bool,
    receivers_ids: PropTypes.arrayOf(
      PropTypes.shape({
        receiver_id: PropTypes.string,
      }),
    ),
  }).isRequired,
}

export default AlertProfile
