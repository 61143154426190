// Copyright © 2024 The Things Industries B.V.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

export const GET_ARS_CONFIGURATION_BASE = 'GET_ARS_CONFIGURATION'
export const [
  {
    request: GET_ARS_CONFIGURATION,
    success: GET_ARS_CONFIGURATION_SUCCESS,
    failure: GET_ARS_CONFIGURATION_FAILURE,
  },
  {
    request: getArsConfiguration,
    success: getArsConfigurationSuccess,
    failure: getArsConfigurationFailure,
  },
] = createRequestActions(GET_ARS_CONFIGURATION_BASE)
