// Copyright © 2023 The Things Industries B.V.

import { createFetchingSelector } from '@ttn-lw/lib/store/selectors/fetching'
import { createErrorSelector } from '@ttn-lw/lib/store/selectors/error'

import { GET_NOC_CONFIGURATION_BASE } from '@console/store/actions/network-operations-center.tti'

const selectNocStore = state => state.noc

export const selectNocConfiguration = state => selectNocStore(state).configuration
export const selectNocConfigurationFetching = createFetchingSelector(GET_NOC_CONFIGURATION_BASE)
export const selectNocConfigurationError = createErrorSelector(GET_NOC_CONFIGURATION_BASE)

export const selectNocAccessConfiguration = state => selectNocConfiguration(state).access || {}
export const selectNocExtendedAccess = state =>
  selectNocAccessConfiguration(state).extended || false
