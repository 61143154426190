// Copyright © 2023 The Things Industries B.V.

import { createFetchingSelector } from '@ttn-lw/lib/store/selectors/fetching'
import { createErrorSelector } from '@ttn-lw/lib/store/selectors/error'

import { GET_SUBSCRIPTION_INFO_BASE } from '../actions/subscription-management.tti'

export const selectSubscriptionInfoStore = state => state.subscriptionInfo
export const selectSubscriptionProduct = state => selectSubscriptionInfoStore(state).product

export const selectSubscriptionInfoError = createErrorSelector(GET_SUBSCRIPTION_INFO_BASE)
export const selectSubscriptionInfoFetching = createFetchingSelector(GET_SUBSCRIPTION_INFO_BASE)
