// Copyright © 2023 The Things Industries B.V.

import tts from '@console/api/tts'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'

import * as noc from '@console/store/actions/network-operations-center.tti'

const getNocConfigurationLogic = createRequestLogic({
  type: noc.GET_NOC_CONFIGURATION,
  process: async () => tts.Noc.getConfiguration(),
})

export default [getNocConfigurationLogic]
