// Copyright © 2023 The Things Industries B.V.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

export const GET_SUBSCRIPTION_INFO_BASE = 'GET_SUBSCRIPTION_INFO'
export const [
  {
    request: GET_SUBSCRIPTION_INFO,
    success: GET_SUBSCRIPTION_INFO_SUCCESS,
    failure: GET_SUBSCRIPTION_INFO_FAILURE,
  },
  {
    request: getSubscriptionInfo,
    success: getSubscriptionInfoSuccess,
    failure: getSubscriptionInfoFailure,
  },
] = createRequestActions(GET_SUBSCRIPTION_INFO_BASE, priceId => ({ priceId }))
