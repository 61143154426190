// Copyright © 2023 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useCallback, useEffect } from 'react'
import { Routes, Route, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import applicationIcon from '@assets/misc/application.svg'

import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import SideNavigation from '@ttn-lw/components/navigation/side'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import Breadcrumbs from '@ttn-lw/components/breadcrumbs'

import GenericNotFound from '@ttn-lw/lib/components/full-view-error/not-found'
import IntlHelmet from '@ttn-lw/lib/components/intl-helmet'
import RequireRequest from '@ttn-lw/lib/components/require-request'

import Require from '@console/lib/components/require'

import ApplicationOverview from '@console/views/application-overview'
import ApplicationGeneralSettings from '@console/views/application-general-settings'
import ApplicationApiKeys from '@console/views/application-api-keys'
import ApplicationCollaborators from '@console/views/application-collaborators'
import ApplicationData from '@console/views/application-data'
import ApplicationPayloadFormatters from '@console/views/application-payload-formatters'
import ApplicationIntegrationsWebhooks from '@console/views/application-integrations-webhooks'
import ApplicationIntegrationsPubsubs from '@console/views/application-integrations-pubsubs'
import ApplicationIntegrationsMqtt from '@console/views/application-integrations-mqtt'
import ApplicationIntegrationsLoRaCloud from '@console/views/application-integrations-lora-cloud'
// TTI only.
import ApplicationIntegrationsAWSIoT from '@console/views/application-integrations-aws-iot/index.tti'
import ApplicationIntegrationsAzureIoT from '@console/views/application-integrations-azure/index.tti'
import ApplicationIntegrationsStorage from '@console/views/application-integrations-storage'
// End TTI.
import Devices from '@console/views/devices'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import {
  selectApplicationSiteName,
  selectNocEnabled,
  selectNocUrl,
} from '@ttn-lw/lib/selectors/env'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'

import {
  mayViewApplicationInfo,
  mayViewApplicationEvents,
  maySetApplicationPayloadFormatters,
  mayViewApplicationDevices,
  mayCreateOrEditApplicationIntegrations,
  mayEditBasicApplicationInfo,
  mayViewOrEditApplicationApiKeys,
  mayViewOrEditApplicationCollaborators,
  mayViewOrEditApplicationPackages,
  mayAddPubSubIntegrations,
  mayViewApplications,
} from '@console/lib/feature-checks'

import {
  getApplication,
  getApplicationsRightsList,
  stopApplicationEventsStream,
} from '@console/store/actions/applications'
import { getAsConfiguration } from '@console/store/actions/application-server'
import { getNocConfiguration } from '@console/store/actions/network-operations-center.tti'

import {
  selectApplicationRights,
  selectSelectedApplication,
} from '@console/store/selectors/applications'
import {
  selectMqttProviderDisabled,
  selectNatsProviderDisabled,
} from '@console/store/selectors/application-server'
import { selectNocExtendedAccess } from '@console/store/selectors/network-operations-center.tti'
import { selectUserIsAdmin } from '@console/store/selectors/logout'

const Application = () => {
  const { appId } = useParams()

  const actions = useCallback(
    async dispatch => {
      await Promise.all([
        dispatch(
          attachPromise(
            getApplication(
              appId,
              'name,description,attributes,dev_eui_counter,network_server_address,application_server_address,join_server_address,administrative_contact,technical_contact',
            ),
          ),
        ),
        dispatch(attachPromise(getApplicationsRightsList(appId))),
        dispatch(attachPromise(getAsConfiguration())),
        // TTI only.
        ...(selectNocEnabled() && Boolean(selectNocUrl())
          ? dispatch(attachPromise(getNocConfiguration()))
          : []),
        // End TTI only.
      ])
    },
    [appId],
  )

  // Check whether application still exists after it has been possibly deleted.
  const application = useSelector(selectSelectedApplication)
  const hasApplication = Boolean(application)

  return (
    <Require featureCheck={mayViewApplications} otherwise={{ redirect: '/' }}>
      <RequireRequest requestAction={actions}>
        {hasApplication && <ApplicationInner />}
      </RequireRequest>
    </Require>
  )
}

const ApplicationInner = () => {
  const { appId } = useParams()
  const application = useSelector(selectSelectedApplication)
  const name = application.name || appId
  const rights = useSelector(selectApplicationRights)
  const siteName = selectApplicationSiteName()
  const natsDisabled = useSelector(selectNatsProviderDisabled)
  const mqttDisabled = useSelector(selectMqttProviderDisabled)

  const dispatch = useDispatch()
  const stopStream = React.useCallback(id => dispatch(stopApplicationEventsStream(id)), [dispatch])

  // TTI only.
  const isAdmin = useSelector(selectUserIsAdmin)
  const nocUrl = selectNocUrl()
  const nocEnabled = selectNocEnabled()
  const nocExtendedAccess = useSelector(selectNocExtendedAccess)
  const showNOCButton = nocEnabled && nocExtendedAccess && Boolean(nocUrl) && isAdmin
  const applicationNocUrl = `${nocUrl}/d/extended-ttsapplication/application-details?var-application_id=${appId}`
  // End TTI.

  useBreadcrumbs('apps.single', <Breadcrumb path={`/applications/${appId}`} content={name} />)

  useEffect(() => () => stopStream(appId), [appId, stopStream])

  return (
    <>
      <Breadcrumbs />
      <IntlHelmet titleTemplate={`%s - ${name} - ${siteName}`} />
      <SideNavigation
        header={{
          icon: applicationIcon,
          iconAlt: sharedMessages.application,
          title: name,
          to: '',
        }}
      >
        {mayViewApplicationInfo.check(rights) && (
          <SideNavigation.Item title={sharedMessages.overview} path="" icon="overview" exact />
        )}
        {mayViewApplicationDevices.check(rights) && (
          <SideNavigation.Item title={sharedMessages.devices} path="devices" icon="devices" />
        )}
        {mayViewApplicationEvents.check(rights) && (
          <SideNavigation.Item title={sharedMessages.liveData} path="data" icon="dvr" />
        )}

        {/* TTI only. */}
        {showNOCButton && (
          <SideNavigation.Item
            title={sharedMessages.noc}
            path={applicationNocUrl}
            icon="data"
            external
          />
        )}
        {/* End TTI. */}

        {maySetApplicationPayloadFormatters.check(rights) && (
          <SideNavigation.Item title={sharedMessages.payloadFormatters} icon="code">
            <SideNavigation.Item
              title={sharedMessages.uplink}
              path="payload-formatters/uplink"
              icon="uplink"
            />
            <SideNavigation.Item
              title={sharedMessages.downlink}
              path="payload-formatters/downlink"
              icon="downlink"
            />
          </SideNavigation.Item>
        )}
        {mayCreateOrEditApplicationIntegrations.check(rights) && (
          <SideNavigation.Item title={sharedMessages.integrations} icon="integration">
            <SideNavigation.Item
              title={sharedMessages.mqtt}
              path="integrations/mqtt"
              icon="extension"
            />
            <SideNavigation.Item
              title={sharedMessages.webhooks}
              path="integrations/webhooks"
              icon="extension"
            />
            {mayAddPubSubIntegrations.check(natsDisabled, mqttDisabled) && (
              <SideNavigation.Item
                title={sharedMessages.pubsubs}
                path="integrations/pubsubs"
                icon="extension"
              />
            )}

            {/* TTI only. */}
            {mayViewOrEditApplicationPackages.check(rights) && (
              <SideNavigation.Item
                title={sharedMessages.storageIntegration}
                path="integrations/storage"
                icon="extension"
              />
            )}
            {mayViewOrEditApplicationPackages.check(rights) && (
              <SideNavigation.Item
                title={sharedMessages.awsIoT}
                path="integrations/aws-iot"
                icon="extension"
              />
            )}
            {mayViewOrEditApplicationPackages.check(rights) && (
              <SideNavigation.Item
                title={sharedMessages.azureIoT}
                path="integrations/azure-iot"
                icon="extension"
              />
            )}
            {/* End TTI. */}

            {mayViewOrEditApplicationPackages.check(rights) && (
              <SideNavigation.Item
                title={sharedMessages.loraCloud}
                path="integrations/lora-cloud"
                icon="extension"
              />
            )}
          </SideNavigation.Item>
        )}
        {mayViewOrEditApplicationCollaborators.check(rights) && (
          <SideNavigation.Item
            title={sharedMessages.collaborators}
            path="collaborators"
            icon="organization"
          />
        )}
        {mayViewOrEditApplicationApiKeys.check(rights) && (
          <SideNavigation.Item title={sharedMessages.apiKeys} path="api-keys" icon="api_keys" />
        )}
        {mayEditBasicApplicationInfo.check(rights) && (
          <SideNavigation.Item
            title={sharedMessages.generalSettings}
            path="general-settings"
            icon="general_settings"
          />
        )}
      </SideNavigation>
      <Routes>
        <Route index Component={ApplicationOverview} />
        <Route path="general-settings" Component={ApplicationGeneralSettings} />
        <Route path="api-keys/*" Component={ApplicationApiKeys} />
        <Route path="devices/*" Component={Devices} />
        <Route path="collaborators/*" Component={ApplicationCollaborators} />
        <Route path="data" Component={ApplicationData} />
        <Route path="payload-formatters/*" Component={ApplicationPayloadFormatters} />
        <Route path="integrations/mqtt" Component={ApplicationIntegrationsMqtt} />
        <Route path="integrations/webhooks/*" Component={ApplicationIntegrationsWebhooks} />
        {mayAddPubSubIntegrations.check(natsDisabled, mqttDisabled) && (
          <Route path="integrations/pubsubs/*" Component={ApplicationIntegrationsPubsubs} />
        )}

        {/* TTI only. */}
        <Route path="integrations/aws-iot" Component={ApplicationIntegrationsAWSIoT} />
        <Route path="integrations/azure-iot" Component={ApplicationIntegrationsAzureIoT} />
        <Route path="integrations/storage" Component={ApplicationIntegrationsStorage} />
        {/* End TTI. */}

        <Route path="integrations/lora-cloud" Component={ApplicationIntegrationsLoRaCloud} />
        <Route path="*" element={<GenericNotFound />} />
      </Routes>
    </>
  )
}

export default Application
