// Copyright © 2023 The Things Industries B.V.

import smApi from '@console/api/subscription-management.tti'

import { httpStatusCode } from '@ttn-lw/lib/errors/utils'
import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'

import * as subscriptionManagement from '@console/store/actions/subscription-management.tti'

const getSubscriptionInfoLogic = createRequestLogic({
  type: subscriptionManagement.GET_SUBSCRIPTION_INFO,
  process: async ({ action }, dispatch) => {
    const { priceId } = action.payload
    let response

    try {
      response = await smApi.subscriptionInfo(priceId)

      return response.data
    } catch (error) {
      if (httpStatusCode(error) === 404) {
        if ('symbol' in error.response.data) {
          return error.response.data
        }
      }

      dispatch(subscriptionManagement.getSubscriptionInfoFailure())
    }
  },
})

export default getSubscriptionInfoLogic
