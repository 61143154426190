// Copyright © 2023 The Things Industries B.V.

import { handleActions } from 'redux-actions'

import { GET_NOC_CONFIGURATION_SUCCESS } from '@console/store/actions/network-operations-center.tti'

const defaultState = {
  configuration: {},
}

export default handleActions(
  {
    [GET_NOC_CONFIGURATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      configuration: payload.configuration,
    }),
  },
  defaultState,
)
