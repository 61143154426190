// Copyright © 2024 The Things Industries B.V.

import { defineMessages } from 'react-intl'

const m = defineMessages({
  addAlertProfile: 'Add an alert profile',
  defaultProfile: 'Default profile',
  defaultProfileMessage:
    'The default profile will be effective for all entities of the network that do not have an alert profile set.',
  dontUseDefault: "Don't use a default",
  noAlertProfiles: 'You have no alert profiles set up currently',
  validateEmptyReceiver:
    'There must be no empty receiver entry. Please remove such entries before submitting.',
  validateReceiverAtLeastOne: 'At least one alert receiver must be selected',
})

export default m
