// Copyright © 2024 The Things Industries B.V.

import { getAlertProfileId, getAlertReceiverId } from '@ttn-lw/lib/selectors/id'

import {
  CREATE_ALERT_NOTIFICATION_PROFILE_SUCCESS,
  CREATE_ALERT_NOTIFICATION_RECEIVER_SUCCESS,
  DELETE_ALERT_NOTIFICATION_PROFILE_SUCCESS,
  DELETE_ALERT_NOTIFICATION_RECEIVER_SUCCESS,
  GET_ALERT_NOTIFICATION_PROFILE_LIST_SUCCESS,
  GET_ALERT_NOTIFICATION_RECEIVER_LIST_SUCCESS,
  UPDATE_ALERT_NOTIFICATION_PROFILE_SUCCESS,
  UPDATE_ALERT_NOTIFICATION_RECEIVER_SUCCESS,
} from '@console/store/actions/alert-notification.tti'

const alertNotification = (state = {}, alertNotification) => ({
  ...state,
  ...alertNotification,
})

const defaultState = {
  profiles: {},
  receivers: {},
}

const alertNotifications = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_ALERT_NOTIFICATION_PROFILE_LIST_SUCCESS:
      const profileEntities = payload.entities.reduce(
        (acc, ap) => {
          const id = getAlertProfileId(ap)

          acc[id] = alertNotification(acc[id], ap)
          return acc
        },
        { ...state.entities },
      )

      return {
        ...state,
        profiles: profileEntities,
      }
    case GET_ALERT_NOTIFICATION_RECEIVER_LIST_SUCCESS:
      const receiverEntities = payload.entities.reduce(
        (acc, ap) => {
          const id = getAlertReceiverId(ap)

          acc[id] = alertNotification(acc[id], ap)
          return acc
        },
        { ...state.entities },
      )

      return {
        ...state,
        receivers: receiverEntities,
      }
    case CREATE_ALERT_NOTIFICATION_PROFILE_SUCCESS:
    case UPDATE_ALERT_NOTIFICATION_PROFILE_SUCCESS:
      const profileId = getAlertProfileId(payload)

      return {
        ...state,
        profiles: {
          ...state.profiles,
          [profileId]: alertNotification(state.profiles[profileId], payload),
        },
      }
    case CREATE_ALERT_NOTIFICATION_RECEIVER_SUCCESS:
    case UPDATE_ALERT_NOTIFICATION_RECEIVER_SUCCESS:
      const receiverId = getAlertReceiverId(payload)

      return {
        ...state,
        receivers: {
          ...state.receivers,
          [receiverId]: payload,
        },
      }
    case DELETE_ALERT_NOTIFICATION_PROFILE_SUCCESS:
      const { [payload.id]: deletedProfile, ...restProfiles } = state.profiles

      return {
        ...state,
        profiles: restProfiles,
      }
    case DELETE_ALERT_NOTIFICATION_RECEIVER_SUCCESS:
      const { [payload.id]: deletedReceiver, ...restReceivers } = state.receivers
      const updatedProfiles = Object.keys(state.profiles).reduce((acc, profileKey) => {
        const profile = state.profiles[profileKey]
        const updatedReceiversIds = (profile.receivers_ids || []).filter(
          receiver => receiver.receiver_id !== payload.id,
        )

        acc[profileKey] = {
          ...profile,
          receivers_ids: !updatedReceiversIds.length ? [{ receiver_id: '' }] : updatedReceiversIds,
        }
        return acc
      }, {})

      return {
        ...state,
        profiles: updatedProfiles,
        receivers: restReceivers,
      }
    default:
      return state
  }
}

export default alertNotifications
