// Copyright © 2023 The Things Industries B.V.

import {
  GET_SUBSCRIPTION_INFO_FAILURE,
  GET_SUBSCRIPTION_INFO_SUCCESS,
} from '@console/store/actions/subscription-management.tti'

const defaultState = {
  product: undefined,
}

const subscriptionInfo = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_SUBSCRIPTION_INFO_SUCCESS:
      return {
        ...state,
        product: payload,
      }
    case GET_SUBSCRIPTION_INFO_FAILURE:
      return {
        ...state,
        product: undefined,
      }
    default:
      return state
  }
}

export default subscriptionInfo
