// Copyright © 2024 The Things Industries B.V.

import { handleActions } from 'redux-actions'

import { GET_ARS_CONFIGURATION_SUCCESS } from '@console/store/actions/alert-routing-server.tti'

const defaultState = {
  configuration: {},
}

export default handleActions(
  {
    [GET_ARS_CONFIGURATION_SUCCESS]: (state, { payload }) => ({
      ...state,
      configuration: payload.configuration,
    }),
  },
  defaultState,
)
