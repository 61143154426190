// Copyright © 2024 The Things Industries B.V.

import React from 'react'

import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import PageTitle from '@ttn-lw/components/page-title'

import IntlHelmet from '@ttn-lw/lib/components/intl-helmet'
import RequireRequest from '@ttn-lw/lib/components/require-request'

import AlertingSettingsContainer from '@console/containers/alerting-settings-container/index.tti'

import Require from '@console/lib/components/require'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { mayConfigureAlertingSettings } from '@console/lib/feature-checks'

import {
  getAlertProfileList,
  getAlertReceiverList,
} from '@console/store/actions/alert-notification.tti'

const AlertingSettings = () => {
  useBreadcrumbs(
    'admin-panel.alerting-settings',
    <Breadcrumb
      path={'/admin-panel/alerting-settings'}
      content={sharedMessages.alertingSettings}
    />,
  )

  return (
    <Require featureCheck={mayConfigureAlertingSettings} otherwise={{ redirect: '/' }}>
      <RequireRequest
        requestAction={[
          getAlertProfileList(undefined, ['is_default', 'receivers_ids']),
          getAlertReceiverList(undefined, ['contact_method']),
        ]}
      >
        <IntlHelmet title={sharedMessages.alertingSettings} />
        <PageTitle title={sharedMessages.alertingSettings} className="panel-title" noGrid />
        <AlertingSettingsContainer />
      </RequireRequest>
    </Require>
  )
}

export default AlertingSettings
