// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useRef, useEffect, useCallback } from 'react'
import { defineMessages } from 'react-intl'
import classnames from 'classnames'

import Icon from '@ttn-lw/components/icon'
import Link from '@ttn-lw/components/link'

import Message from '@ttn-lw/lib/components/message'

import PropTypes from '@ttn-lw/lib/prop-types'

import style from './disclaimer-cycler.styl'

const m = defineMessages({
  fairUsePolicyApplies: 'Fair use policy applies',
  slaApplies: '{sla} SLA applies',
  noSla: 'No SLA applicable',
  supportPlanApplies: '{support} support applies',
  noSupportPlan: 'No support plan',
})

const DURATION_CYCLE = 1400
const DURATION_CYCLE_RETAINER = 15
const DURATION_DEFAULT_INTERVAL = 12000

const Disclaimer = ({
  slaApplies,
  slaInformationUrl,
  fairUsePolicyInformationUrl,
  supportPlanApplies,
  supportPlanInformationUrl,
  interval,
  align,
}) => {
  const containerRef = useRef(null)
  const paused = useRef(false)

  const noSlaApplies = slaApplies === 'no'
  const noSupportPlanApplies = supportPlanApplies === 'no'

  const pauseAnimation = useCallback(() => {
    paused.current = true
  }, [])

  const resumeAnimation = useCallback(() => {
    paused.current = false
  }, [])

  useEffect(() => {
    const { current: container } = containerRef
    if (!container || !container.children) {
      return
    }
    container.children[0].classList.add(style.visible)
    if (container.childElementCount < 2) {
      return
    }
    const max = container.childElementCount - 1
    const height = container.offsetHeight
    let i = 0
    container.appendChild(container.firstChild.cloneNode(true))

    window.addEventListener('focus', resumeAnimation)
    window.addEventListener('blur', pauseAnimation)

    const animation = setInterval(
      () => {
        if (paused.current) {
          return
        }
        container.classList.add(style.fade)
        container.style.top = `${parseInt(container.style.top || 0) - height}px`
        if (i === max) {
          container.children[0].classList.add(style.visible)
          container.children[max + 1].classList.add(style.visible)
          container.children[max].classList.remove(style.visible)
        } else if (i === 0) {
          container.children[max + 1].classList.remove(style.visible)
          container.children[0].classList.remove(style.visible)
          container.children[1].classList.add(style.visible)
        } else {
          container.children[i + 1].classList.add(style.visible)
          container.children[i].classList.remove(style.visible)
        }
        if (i >= max) {
          i = 0
          setTimeout(() => {
            container.classList.remove(style.transitioned)
            container.style.top = '0px'
            setTimeout(() => {
              container.classList.add(style.transitioned)
            }, DURATION_CYCLE_RETAINER)
          }, DURATION_CYCLE)
        } else {
          i++
        }
      },
      Math.max(DURATION_CYCLE + DURATION_CYCLE_RETAINER, interval),
    )

    // Clean up function on unmount.
    return () => {
      clearInterval(animation)
      window.removeEventListener('focus', resumeAnimation)
      window.removeEventListener('blur', pauseAnimation)
    }
  }, [interval, pauseAnimation, resumeAnimation])

  if (
    !Boolean(slaApplies) &&
    !Boolean(fairUsePolicyInformationUrl) &&
    !Boolean(supportPlanApplies)
  ) {
    return null
  }

  return (
    <div
      className={classnames(style.container, style[`align-${align}`])}
      onMouseEnter={pauseAnimation}
      onMouseLeave={resumeAnimation}
    >
      <div className={classnames(style.cycle, style.transitioned)} ref={containerRef}>
        {fairUsePolicyInformationUrl && (
          <div>
            <Message className={style.warning} content={m.fairUsePolicyApplies} />
            {fairUsePolicyInformationUrl && (
              <Link.Anchor
                className={style.icon}
                target="_blank"
                href={fairUsePolicyInformationUrl}
                tabIndex="-1"
              >
                <Icon icon="help_outline" small nudgeUp />
              </Link.Anchor>
            )}
          </div>
        )}
        {slaApplies && (
          <div>
            <Message
              className={classnames({ [style.warning]: noSlaApplies })}
              content={noSlaApplies ? m.noSla : m.slaApplies}
              values={{ sla: slaApplies }}
              firstToUpper
            />
            {slaInformationUrl && (
              <Link.Anchor
                className={style.icon}
                target="_blank"
                href={slaInformationUrl}
                tabIndex="-1"
              >
                <Icon icon="help_outline" small nudgeUp />
              </Link.Anchor>
            )}
          </div>
        )}
        {supportPlanApplies && (
          <div>
            <Message
              className={classnames({ [style.warning]: noSupportPlanApplies })}
              content={noSupportPlanApplies ? m.noSupportPlan : m.supportPlanApplies}
              values={{ support: supportPlanApplies }}
              firstToUpper
            />
            {supportPlanInformationUrl && (
              <Link.Anchor
                className={style.icon}
                target="_blank"
                href={supportPlanInformationUrl}
                tabIndex="-1"
              >
                <Icon icon="help_outline" small nudgeUp />
              </Link.Anchor>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

Disclaimer.propTypes = {
  /** The alignment of the disclaimer text. */
  align: PropTypes.oneOf(['center', 'left', 'right']),
  /** The URL for further information on the fair use policy. */
  fairUsePolicyInformationUrl: PropTypes.string,
  /** The interval in which the disclaimer text are cycled. */
  interval: PropTypes.number,
  /** The type of SLA that applies on this deployment. */
  slaApplies: PropTypes.string,
  /** The URL for further information on the SLA. */
  slaInformationUrl: PropTypes.string,
  /** The support plan that applies. */
  supportPlanApplies: PropTypes.string,
  /** The URL for further information on the fair use policy. */
  supportPlanInformationUrl: PropTypes.string,
}

Disclaimer.defaultProps = {
  align: 'center',
  interval: DURATION_DEFAULT_INTERVAL,
  slaApplies: undefined,
  slaInformationUrl: undefined,
  fairUsePolicyInformationUrl: undefined,
  supportPlanApplies: undefined,
  supportPlanInformationUrl: undefined,
}

export default Disclaimer
