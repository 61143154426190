// Copyright © 2021 The Things Industries B.V.

import React, { useCallback, useState } from 'react'
import { defineMessages } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import AWS_IOT_INTEGRATION from '@console/constants/aws-iot-integration.tti'

import Form from '@ttn-lw/components/form'
import Input from '@ttn-lw/components/input'
import Select from '@ttn-lw/components/select'
import SubmitBar from '@ttn-lw/components/submit-bar'
import SubmitButton from '@ttn-lw/components/submit-button'
import ModalButton from '@ttn-lw/components/button/modal-button'
import toast from '@ttn-lw/components/toast'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import Yup from '@ttn-lw/lib/yup'
import sharedMessages from '@ttn-lw/lib/shared-messages'
import { isNotFoundError } from '@ttn-lw/lib/errors/utils'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'

import {
  setAppPkgDefaultAssoc,
  getAppPkgDefaultAssoc,
  deleteAppPkgDefaultAssoc,
} from '@console/store/actions/application-packages'

import { selectSelectedApplicationId } from '@console/store/selectors/applications'
import {
  selectApplicationPackageDefaultAssociation,
  selectGetApplicationPackagesError,
} from '@console/store/selectors/application-packages'

import regions from './regions.tti'

const m = defineMessages({
  assumeRoleArn: 'Role ARN',
  assumeRoleArnDescription: 'Copy the CloudFormation stack "CrossAccountRoleArn" output',
  disable: 'Disable AWS IoT integration',
  disabled: 'AWS IoT integration disabled',
  disableWarning: 'Are you sure you want to disable the integration?',
  enable: 'Enable AWS IoT integration',
  enabled: 'AWS IoT integration enabled',
  region: 'Region',
  stackName: 'CloudFormation stack name',
  stackNameDescription:
    'Copy the CloudFormation stack name that you used when deploying the integration in your AWS account',
  update: 'Update AWS IoT integration',
  updated: 'AWS IoT integration updated',
  validateRoleArnFormat: '{field} must be a valid AWS IAM role ARN',
  validateStackNameFormat: '{field} must be a valid AWS CloudFormation stack name',
})

const validationSchema = Yup.object()
  .shape({
    data: Yup.object().shape({
      region: Yup.string().oneOf(Object.keys(regions)).required(sharedMessages.validateRequired),
      stack_name: Yup.string()
        .matches(/^[A-Za-z][A-Za-z0-9-]*$/, Yup.passValues(m.validateStackNameFormat))
        .required(sharedMessages.validateRequired),
      assume_role_arn: Yup.string()
        .matches(
          /^arn:aws:iam::[0-9]{12}:role\/[A-Za-z0-9_+=,.@-]+$/,
          Yup.passValues(m.validateRoleArnFormat),
        )
        .required(sharedMessages.validateRequired),
    }),
  })
  .noUnknown()

const defaultValues = {
  data: {
    region: '',
    stack_name: '',
    assume_role_arn: '',
  },
}

const promisifiedSetAppPkgDefaultAssoc = attachPromise(setAppPkgDefaultAssoc)
const promisifiedDeleteAppPkgDefaultAssoc = attachPromise(deleteAppPkgDefaultAssoc)

const AWSIoTIntegrationForm = () => {
  const [error, setError] = useState('')
  const appId = useSelector(selectSelectedApplicationId)
  const selector = ['data']

  const dispatch = useDispatch()
  const defaultAssociation = useSelector(state =>
    selectApplicationPackageDefaultAssociation(state, AWS_IOT_INTEGRATION.DEFAULT_PORT),
  )
  const packageError = useSelector(selectGetApplicationPackagesError)
  const initialValues = validationSchema.cast(defaultAssociation || defaultValues, {
    stripUnknown: true,
  })

  const handleSubmit = useCallback(
    async values => {
      try {
        await dispatch(
          promisifiedSetAppPkgDefaultAssoc(appId, AWS_IOT_INTEGRATION.DEFAULT_PORT, {
            package_name: AWS_IOT_INTEGRATION.DEFAULT_PACKAGE_NAME,
            ...values,
          }),
        )
        toast({
          message: Boolean(defaultAssociation) ? m.updated : m.enabled,
          type: toast.types.SUCCESS,
        })
      } catch (error) {
        setError(error)
      }
    },
    [appId, dispatch, defaultAssociation],
  )

  const handleDisable = useCallback(async () => {
    try {
      await dispatch(
        promisifiedDeleteAppPkgDefaultAssoc(appId, AWS_IOT_INTEGRATION.DEFAULT_PORT, {
          package_name: AWS_IOT_INTEGRATION.DEFAULT_PACKAGE_NAME,
        }),
      )
      toast({
        message: m.disabled,
        type: toast.types.SUCCESS,
      })
    } catch (error) {
      setError(error)
    }
  }, [appId, dispatch])

  if (packageError && !isNotFoundError(packageError)) {
    throw packageError
  }

  return (
    <RequireRequest
      requestAction={getAppPkgDefaultAssoc(appId, AWS_IOT_INTEGRATION.DEFAULT_PORT, selector)}
    >
      <Form
        error={error}
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleSubmit}
      >
        <Form.Field
          name="data.region"
          title={m.region}
          component={Select}
          options={Object.keys(regions)
            .map(region => ({ value: region, label: `${regions[region]} (${region})` }))
            .sort((a, b) => a.label.localeCompare(b.label))}
        />
        <Form.Field
          name="data.stack_name"
          title={m.stackName}
          component={Input}
          description={m.stackNameDescription}
          required
        />
        <Form.Field
          name="data.assume_role_arn"
          title={m.assumeRoleArn}
          component={Input}
          description={m.assumeRoleArnDescription}
          placeholder={m.assumeRoleArnPlaceholder}
          required
        />
        <SubmitBar>
          <Form.Submit
            component={SubmitButton}
            message={Boolean(defaultAssociation) ? m.update : m.enable}
          />
          {Boolean(defaultAssociation) && (
            <ModalButton
              type="button"
              message={m.disable}
              modalData={{
                message: m.disableWarning,
              }}
              onApprove={handleDisable}
              danger
              naked
            />
          )}
        </SubmitBar>
      </Form>
    </RequireRequest>
  )
}

export default AWSIoTIntegrationForm
