// Copyright © 2024 The Things Industries B.V.

import React, { useCallback, useState } from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import Form from '@ttn-lw/components/form'
import Radio from '@ttn-lw/components/radio-button'
import Input from '@ttn-lw/components/input'
import Button from '@ttn-lw/components/button'
import KeyValueMap from '@ttn-lw/components/key-value-map'
import Modal from '@ttn-lw/components/modal'
import PhoneInput from '@ttn-lw/components/phone-input'

import { RECEIVER_TYPE } from '@console/containers/alert-receivers-form/validation-schema.tti'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import tooltipIds from '@ttn-lw/lib/constants/tooltip-ids'
import PropTypes from '@ttn-lw/lib/prop-types'

import { selectAlertProfilesStore } from '@console/store/selectors/alert-notification.tti'

const m = defineMessages({
  additionalHeaders: 'Additional Headers',
  emailAddressPlaceholder: 'tech-support@example.com',
  receiverType: 'Receiver type',
  receiverId: 'Receiver ID',
  receiverIdPlaceholder: 'E.g. tech-support',
  removeReceiver: 'Remove this receiver',
  removeReceiverDescription:
    'This alert receiver is still connected to the following profile(s): `{usedProfiles}`. Removing it will cause these profiles to not use this receiver anymore. Are you sure you want to remove them?',
  removeReceiverTitle: 'Confirm removal',
  phoneNumber: 'Phone number',
  phoneNumberPlaceholder: 'E.g. 97123123123',
  sms: 'SMS',
  url: 'URL',
  urlPlaceholder: 'https://example.com/path/to/webhook',
  webhook: 'Webhook',
})

const isReadOnly = value => value.readOnly

const encodeHeaders = value =>
  value.reduce((acc, { key, value: val }) => ({ ...acc, [key]: val }), {})

const decodeHeaders = value =>
  Object.entries(value ?? {}).map(([key, value]) => ({ key, value }), [])

const AlertReceiver = props => {
  const alertProfiles = useSelector(selectAlertProfilesStore)
  const [displayRemoveReceiverModal, setDisplayRemoveReceiverModal] = useState(false)
  const [usedProfiles, setUsedProfiles] = useState('')
  const { receiver, handleRemoveReceiver, index } = props

  const isFromState = Boolean(receiver.created_at)

  const completeReceiverRemoval = useCallback(() => {
    handleRemoveReceiver(index)
  }, [handleRemoveReceiver, index])

  const handleRemoveReceiverClick = useCallback(() => {
    if (isFromState) {
      const profileIds = Object.values(alertProfiles)
        .filter(p => p.receivers_ids?.map(pr => pr.receiver_id).includes(receiver.ids.receiver_id))
        .map(p => p.ids.profile_id)
      if (profileIds.length) {
        setUsedProfiles(profileIds.join(', '))
        setDisplayRemoveReceiverModal(true)
        return
      }
    }
    completeReceiverRemoval()
  }, [alertProfiles, completeReceiverRemoval, isFromState, receiver.ids.receiver_id])

  const handleOnCancelRemoveReceiverModal = useCallback(() => {
    setDisplayRemoveReceiverModal(false)
  }, [])

  const emailSection = (
    <Form.Field
      title={sharedMessages.emailAddress}
      name={`receivers.${props.index}.email.recipient`}
      placeholder={m.emailAddressPlaceholder}
      required
      component={Input}
    />
  )

  const smsSection = (
    <Form.Field
      title={m.phoneNumber}
      name={`receivers.${props.index}.sms.phone_number`}
      placeholder={m.phoneNumberPlaceholder}
      required
      component={PhoneInput}
      inputWidth="s"
    />
  )

  const webhookSection = (
    <>
      <Form.Field
        title={m.url}
        name={`receivers.${props.index}.webhook.url`}
        placeholder={m.urlPlaceholder}
        required
        component={Input}
      />

      <Form.Field
        name={`receivers.${props.index}.webhook.headers`}
        title={m.additionalHeaders}
        keyPlaceholder={sharedMessages.authorization}
        valuePlaceholder={sharedMessages.bearerMyAuthToken}
        addMessage={sharedMessages.addHeaderEntry}
        component={KeyValueMap}
        isReadOnly={isReadOnly}
        encode={encodeHeaders}
        decode={decodeHeaders}
      />
    </>
  )

  return (
    <>
      <Form.Field
        title={m.receiverId}
        name={`receivers.${props.index}.ids.receiver_id`}
        placeholder={m.receiverIdPlaceholder}
        required
        component={Input}
        autoFocus
        readOnly={isFromState}
      />
      <Form.Field
        title={m.receiverType}
        name={`receivers.${props.index}._receiver_type`}
        component={Radio.Group}
        horizontal
        tooltipId={tooltipIds.RECEIVER_TYPE}
      >
        <Radio label={sharedMessages.email} value={RECEIVER_TYPE.EMAIL} />
        <Radio label={m.sms} value={RECEIVER_TYPE.SMS} />
        <Radio label={m.webhook} value={RECEIVER_TYPE.WEBHOOK} />
      </Form.Field>
      {props.receiver._receiver_type === RECEIVER_TYPE.EMAIL && emailSection}
      {props.receiver._receiver_type === RECEIVER_TYPE.SMS && smsSection}
      {props.receiver._receiver_type === RECEIVER_TYPE.WEBHOOK && webhookSection}
      <div className="mt-cs-l">
        <Button
          name="remove_receiver"
          type="button"
          message={m.removeReceiver}
          onClick={handleRemoveReceiverClick}
          danger
        />
        {displayRemoveReceiverModal && (
          <Modal
            title={m.removeReceiverTitle}
            message={{ ...m.removeReceiverDescription, values: { usedProfiles } }}
            buttonMessage={sharedMessages.remove}
            onComplete={completeReceiverRemoval}
            danger
            cancelButtonProps={{ onClick: handleOnCancelRemoveReceiverModal }}
          />
        )}
      </div>
    </>
  )
}

AlertReceiver.propTypes = {
  handleRemoveReceiver: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  receiver: PropTypes.shape({
    ids: PropTypes.shape({
      receiver_id: PropTypes.string,
    }),
    created_at: PropTypes.string,
    updated_at: PropTypes.string,
    email: PropTypes.shape({
      recipient: PropTypes.string,
    }),
    sms: PropTypes.shape({
      phone_number: PropTypes.string,
    }),
    webhook: PropTypes.shape({
      url: PropTypes.string,
      headers: PropTypes.object,
    }),
    _receiver_type: PropTypes.oneOf([RECEIVER_TYPE.EMAIL, RECEIVER_TYPE.SMS, RECEIVER_TYPE.WEBHOOK])
      .isRequired,
  }).isRequired,
}
export default AlertReceiver
