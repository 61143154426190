// Copyright © 2023 The Things Industries B.V.

export const CLOUD_PLAN_DISCOVERY = 'DISCOVERY'
export const CLOUD_PLAN_STANDARD = 'STANDARD'
export const CLOUD_PLAN_PLUS = 'PLUS'
export const CLOUD_PLAN_MANUAL_BILLING = 'MANUAL-BILLING'

export const REGULAR_CLOUD_PLANS = [CLOUD_PLAN_DISCOVERY, CLOUD_PLAN_STANDARD, CLOUD_PLAN_PLUS]
export const CLOUD_PLANS = [...REGULAR_CLOUD_PLANS, CLOUD_PLAN_MANUAL_BILLING]

export const bulletpoints = Object.freeze({
  STANDARD: [
    'First 1000 devices included for free',
    'Unlimited gateways and applications',
    '99.9% guaranteed uptime',
    'Gateway Network Operations Center',
  ],
  PLUS: [
    'Includes all Standard features',
    'Gateway/Device Network Operations Center',
    'Standard support included',
    'Security assessments',
  ],
})

export const UPGRADE = Object.freeze({
  DISCOVERY: 'STANDARD',
  STANDARD: 'PLUS',
})

export const getEntity = key => {
  const entity = key.split(/-(.*)/s)[1]
  return entity.includes('-') ? entity.replace('-', '_') : entity
}

export const calculatePercentage = (current, target) => (parseInt(current) / parseInt(target)) * 100
