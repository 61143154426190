// Copyright © 2024 The Things Industries B.V.

import tts from '@console/api/tts'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'

import * as alertNotifications from '@console/store/actions/alert-notification.tti'

// Alert Profiles

const getAlertProfilesLogic = createRequestLogic({
  type: alertNotifications.GET_ALERT_NOTIFICATION_PROFILE_LIST,
  process: async ({ action }) => {
    const {
      params: { page, limit, order },
    } = action.payload
    const { selectors } = action.meta

    const data = await tts.AlertNotification.getAllProfiles({ page, limit, order }, selectors)

    return { entities: data.profiles, totalCount: data.totalCount }
  },
})

const createAlertProfileLogic = createRequestLogic({
  type: alertNotifications.CREATE_ALERT_NOTIFICATION_PROFILE,
  process: async ({ action }) => {
    const { profile } = action.payload

    return await tts.AlertNotification.createProfile(profile)
  },
})

const updateAlertProfileLogic = createRequestLogic({
  type: alertNotifications.UPDATE_ALERT_NOTIFICATION_PROFILE,
  process: async ({ action }) => {
    const {
      payload: { id, patch },
    } = action
    const result = await tts.AlertNotification.updateProfileById(id, patch)

    return { ...patch, ...result }
  },
})

const deleteAlertProfileLogic = createRequestLogic({
  type: alertNotifications.DELETE_ALERT_NOTIFICATION_PROFILE,
  process: async ({ action }) => {
    const { id } = action.payload

    await tts.AlertNotification.deleteProfileById(id)

    return { id }
  },
})

// Alert Receivers

const getAlertReceiversLogic = createRequestLogic({
  type: alertNotifications.GET_ALERT_NOTIFICATION_RECEIVER_LIST,
  latest: true,
  process: async ({ action }) => {
    const {
      params: { page, limit, order },
    } = action.payload
    const { selectors } = action.meta

    const data = await tts.AlertNotification.getAllReceivers({ page, limit, order }, selectors)

    return { entities: data.receivers, totalCount: data.totalCount }
  },
})

const createAlertReceiverLogic = createRequestLogic({
  type: alertNotifications.CREATE_ALERT_NOTIFICATION_RECEIVER,
  process: async ({ action }) => {
    const { receiver } = action.payload

    return await tts.AlertNotification.createReceiver(receiver)
  },
})

const updateAlertReceiverLogic = createRequestLogic({
  type: alertNotifications.UPDATE_ALERT_NOTIFICATION_RECEIVER,
  process: async ({ action }) => {
    const {
      payload: { id, patch, fieldMask },
    } = action
    const result = await tts.AlertNotification.updateReceiverById(id, patch, fieldMask)

    return { ...patch, ...result }
  },
})

const deleteAlertReceiverLogic = createRequestLogic({
  type: alertNotifications.DELETE_ALERT_NOTIFICATION_RECEIVER,
  process: async ({ action }) => {
    const { id } = action.payload

    await tts.AlertNotification.deleteReceiverById(id)

    return { id }
  },
})

export default [
  getAlertProfilesLogic,
  getAlertReceiversLogic,
  createAlertProfileLogic,
  createAlertReceiverLogic,
  updateAlertProfileLogic,
  updateAlertReceiverLogic,
  deleteAlertProfileLogic,
  deleteAlertReceiverLogic,
]
