// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useMemo } from 'react'
import { defineMessages } from 'react-intl'
import { useSelector } from 'react-redux'

import Select from '@ttn-lw/components/select'
import Form from '@ttn-lw/components/form'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import PropTypes from '@ttn-lw/lib/prop-types'
import { selectArsEnabled } from '@ttn-lw/lib/selectors/env'

import { checkFromState, mayConfigureAlertingSettings } from '@console/lib/feature-checks'

import { getAlertProfileList } from '@console/store/actions/alert-notification.tti'
import { getArsConfiguration } from '@console/store/actions/alert-routing-server.tti'

import { selectAlertProfilesStore } from '@console/store/selectors/alert-notification.tti'

const m = defineMessages({
  alertProfile: 'Alert profile',
})

const AlertProfilesSelect = props => {
  const { name, required } = props

  const alertProfiles = useSelector(selectAlertProfilesStore)
  const arsEnabled = selectArsEnabled()
  const showSelect = useSelector(state => checkFromState(mayConfigureAlertingSettings, state))

  const profileOptions = useMemo(
    () =>
      Object.keys(alertProfiles).map(p => ({
        value: p,
        label: p,
      })),
    [alertProfiles],
  )

  return (
    arsEnabled && (
      <RequireRequest requestAction={[getArsConfiguration(), getAlertProfileList()]}>
        {showSelect && (
          <>
            <Form.Field
              title={m.alertProfile}
              name={name}
              component={Select}
              options={profileOptions}
              required={required}
            />
            {/* TODO: Include explanation about alerting, referencing the documentation*/}
            {/* <Message content={m.alertProfileDescription} component="p" className="mt-cs-xs tc-subtle-gray" />*/}
          </>
        )}
      </RequireRequest>
    )
  )
}

AlertProfilesSelect.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
}

AlertProfilesSelect.defaultProps = {
  required: false,
}

export default AlertProfilesSelect
