// Copyright © 2024 The Things Industries B.V.

import Yup from '@ttn-lw/lib/yup'
import { id as receiverIdRegexp, phoneNumber as phoneNumberRegexp } from '@ttn-lw/lib/regexp'
import sharedMessages from '@ttn-lw/lib/shared-messages'

export const RECEIVER_TYPE = Object.freeze({
  EMAIL: 'EMAIL',
  SMS: 'SMS',
  WEBHOOK: 'WEBHOOK',
})

const hasNoEmptyEntry = headers =>
  Object.values(headers).every(value => value !== '' && value !== undefined)

const validationSchema = Yup.object().shape({
  receivers: Yup.array().of(
    Yup.object().shape({
      _receiver_type: Yup.string().oneOf([
        RECEIVER_TYPE.EMAIL,
        RECEIVER_TYPE.SMS,
        RECEIVER_TYPE.WEBHOOK,
      ]),
      ids: Yup.object().shape({
        receiver_id: Yup.string()
          .matches(receiverIdRegexp, Yup.passValues(sharedMessages.validateIdFormat))
          .min(2, Yup.passValues(sharedMessages.validateTooShort))
          .max(36, Yup.passValues(sharedMessages.validateTooLong))
          .required(sharedMessages.validateRequired),
      }),
      email: Yup.object().when('_receiver_type', {
        is: RECEIVER_TYPE.EMAIL,
        then: schema =>
          schema.shape({
            recipient: Yup.string()
              .email(sharedMessages.validateEmail)
              .required(sharedMessages.validateRequired),
          }),
        otherwise: schema => schema.strip(),
      }),
      sms: Yup.object().when('_receiver_type', {
        is: RECEIVER_TYPE.SMS,
        then: schema =>
          schema.shape({
            phone_number: Yup.string()
              .required(sharedMessages.validateRequired)
              .matches(phoneNumberRegexp, Yup.passValues(sharedMessages.validatePhoneNumber)),
          }),
        otherwise: schema => schema.strip(),
      }),
      webhook: Yup.object().when('_receiver_type', {
        is: RECEIVER_TYPE.WEBHOOK,
        then: schema =>
          schema.shape({
            url: Yup.string()
              .url(sharedMessages.validateUrl)
              .required(sharedMessages.validateRequired),
            headers: Yup.object()
              .test('has no empty entry', sharedMessages.validateEmptyHeader, hasNoEmptyEntry)
              .default({}),
          }),
        otherwise: schema => schema.strip(),
      }),
    }),
  ),
})

export default validationSchema
