// Copyright © 2023 The Things Industries B.V.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

export const GET_NOC_CONFIGURATION_BASE = 'GET_NOC_CONFIGURATION'
export const [
  {
    request: GET_NOC_CONFIGURATION,
    success: GET_NOC_CONFIGURATION_SUCCESS,
    failure: GET_NOC_CONFIGURATION_FAILURE,
  },
  {
    request: getNocConfiguration,
    success: getNocConfigurationSuccess,
    failure: getNocConfigurationFailure,
  },
] = createRequestActions(GET_NOC_CONFIGURATION_BASE)
