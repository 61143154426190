// Copyright © 2022 The Things Industries B.V.

import React from 'react'
import { defineMessages } from 'react-intl'

import Checkbox from '@ttn-lw/components/checkbox'
import Form from '@ttn-lw/components/form'

import Yup from '@ttn-lw/lib/yup'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import style from './style.tti.styl'

const AzureIoTMessages = defineMessages({
  reportedPropertiesFilter: 'Reported properties filter',
  reportedPropertiesFilterInfo:
    'For each enabled message type, the integration will update the reported properties on behalf of the end device',
  eventFilter: 'Event filter',
  eventFilterInfo:
    'For each enabled message type, the integration will submit device events on behalf of the end device',
})

const AzureIoTApplicationUpFilterSchema = Yup.object().shape({
  uplink_message: Yup.boolean(),
  uplink_normalized: Yup.boolean(),
  join_accept: Yup.boolean(),
  downlink_ack: Yup.boolean(),
  downlink_nack: Yup.boolean(),
  downlink_sent: Yup.boolean(),
  downlink_failed: Yup.boolean(),
  downlink_queued: Yup.boolean(),
  downlink_queue_invalidated: Yup.boolean(),
  location_solved: Yup.boolean(),
  service_data: Yup.boolean(),
})

const AzureIoTDefaultApplicationUpFilterValues = {
  uplink_message: true,
  uplink_normalized: true,
  join_accept: true,
  downlink_ack: true,
  downlink_nack: true,
  downlink_sent: true,
  downlink_failed: true,
  downlink_queued: true,
  downlink_queue_invalidated: true,
  location_solved: true,
  service_data: true,
}

const CreateAzureIoTApplicationUpFilterField = filterDescriptor => (
  <Form.Field
    name={filterDescriptor.name}
    title={filterDescriptor.title}
    component={Checkbox.Group}
    description={filterDescriptor.info}
    horizontal
  >
    {[
      { path: 'uplink_message', name: 'uplinkMessage' },
      { path: 'uplink_normalized', name: 'uplinkNormalized' },
      { path: 'join_accept', name: 'joinAccept' },
      { path: 'downlink_ack', name: 'downlinkAck' },
      { path: 'downlink_nack', name: 'downlinkNack' },
      { path: 'downlink_sent', name: 'downlinkSent' },
      { path: 'downlink_failed', name: 'downlinkFailed' },
      { path: 'downlink_queued', name: 'downlinkQueued' },
      { path: 'downlink_queue_invalidated', name: 'downlinkQueueInvalidated' },
      { path: 'location_solved', name: 'locationSolved' },
      { path: 'service_data', name: 'serviceData' },
    ].map(fieldDescriptor => (
      <Checkbox
        label={sharedMessages[fieldDescriptor.name]}
        className={style.checkbox}
        name={`${fieldDescriptor.path}`}
        key={`${fieldDescriptor.path}`}
      />
    ))}
  </Form.Field>
)

export {
  AzureIoTMessages,
  AzureIoTApplicationUpFilterSchema,
  AzureIoTDefaultApplicationUpFilterValues,
  CreateAzureIoTApplicationUpFilterField,
}
