// Copyright © 2024 The Things Industries B.V.

import {
  createPaginationDeleteActions,
  createPaginationRequestActions,
} from '@ttn-lw/lib/store/actions/pagination'
import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

// Alert Profiles
export const ALERT_NOTIFICATION_PROFILE_BASE = 'ALERT_NOTIFICATION_PROFILE'
export const [
  {
    request: GET_ALERT_NOTIFICATION_PROFILE_LIST,
    success: GET_ALERT_NOTIFICATION_PROFILE_LIST_SUCCESS,
    failure: GET_ALERT_NOTIFICATION_PROFILE_LIST_FAILURE,
  },
  {
    request: getAlertProfileList,
    success: getAlertProfileListSuccess,
    failure: getAlertProfileListFailure,
  },
] = createPaginationRequestActions(ALERT_NOTIFICATION_PROFILE_BASE)

export const [
  {
    request: CREATE_ALERT_NOTIFICATION_PROFILE,
    success: CREATE_ALERT_NOTIFICATION_PROFILE_SUCCESS,
    failure: CREATE_ALERT_NOTIFICATION_PROFILE_FAILURE,
  },
  {
    request: createAlertProfile,
    success: createAlertProfileSuccess,
    failure: createAlertProfileFailure,
  },
] = createRequestActions(`CREATE_${ALERT_NOTIFICATION_PROFILE_BASE}`, profile => ({
  profile,
}))

export const [
  {
    request: UPDATE_ALERT_NOTIFICATION_PROFILE,
    success: UPDATE_ALERT_NOTIFICATION_PROFILE_SUCCESS,
    failure: UPDATE_ALERT_NOTIFICATION_PROFILE_FAILURE,
  },
  {
    request: updateAlertProfile,
    success: updateAlertProfileSuccess,
    failure: updateAlertProfileFailure,
  },
] = createRequestActions(
  `UPDATE_${ALERT_NOTIFICATION_PROFILE_BASE}`,
  (id, patch) => ({ id, patch }),
  (id, patch, selector) => ({ selector }),
)

export const [
  {
    request: DELETE_ALERT_NOTIFICATION_PROFILE,
    success: DELETE_ALERT_NOTIFICATION_PROFILE_SUCCESS,
    failure: DELETE_ALERT_NOTIFICATION_PROFILE_FAILURE,
  },
  {
    request: deleteAlertProfile,
    success: deleteAlertProfileSuccess,
    failure: deleteAlertProfileFailure,
  },
] = createPaginationDeleteActions(ALERT_NOTIFICATION_PROFILE_BASE, id => ({ id }))

// Alert Receivers
export const ALERT_NOTIFICATION_RECEIVER_BASE = 'ALERT_NOTIFICATION_RECEIVER'
export const [
  {
    request: GET_ALERT_NOTIFICATION_RECEIVER_LIST,
    success: GET_ALERT_NOTIFICATION_RECEIVER_LIST_SUCCESS,
    failure: GET_ALERT_NOTIFICATION_RECEIVER_LIST_FAILURE,
  },
  {
    request: getAlertReceiverList,
    success: getAlertReceiverListSuccess,
    failure: getAlertReceiverListFailure,
  },
] = createPaginationRequestActions(ALERT_NOTIFICATION_RECEIVER_BASE)

export const [
  {
    request: CREATE_ALERT_NOTIFICATION_RECEIVER,
    success: CREATE_ALERT_NOTIFICATION_RECEIVER_SUCCESS,
    failure: CREATE_ALERT_NOTIFICATION_RECEIVER_FAILURE,
  },
  {
    request: createAlertReceiver,
    success: createAlertReceiverSuccess,
    failure: createAlertReceiverFailure,
  },
] = createRequestActions(`CREATE_${ALERT_NOTIFICATION_RECEIVER_BASE}`, receiver => ({
  receiver,
}))

export const [
  {
    request: UPDATE_ALERT_NOTIFICATION_RECEIVER,
    success: UPDATE_ALERT_NOTIFICATION_RECEIVER_SUCCESS,
    failure: UPDATE_ALERT_NOTIFICATION_RECEIVER_FAILURE,
  },
  {
    request: updateAlertReceiver,
    success: updateAlertReceiverSuccess,
    failure: updateAlertReceiverFailure,
  },
] = createRequestActions(
  `UPDATE_${ALERT_NOTIFICATION_RECEIVER_BASE}`,
  (id, patch, fieldMask) => ({ id, patch, fieldMask }),
  (id, patch, selector) => ({ selector }),
)

export const [
  {
    request: DELETE_ALERT_NOTIFICATION_RECEIVER,
    success: DELETE_ALERT_NOTIFICATION_RECEIVER_SUCCESS,
    failure: DELETE_ALERT_NOTIFICATION_RECEIVER_FAILURE,
  },
  {
    request: deleteAlertReceiver,
    success: deleteAlertReceiverSuccess,
    failure: deleteAlertReceiverFailure,
  },
] = createPaginationDeleteActions(ALERT_NOTIFICATION_RECEIVER_BASE, id => ({ id }))
