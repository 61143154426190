// Copyright © 2022 The Things Industries B.V.

import * as Yup from 'yup'

import { url as urlRegExp, hostname as hostnameRegExp } from '@ttn-lw/lib/regexp'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import { devAddrPrefix as devAddrPrefixRegExp } from '@console/lib/regexp'

import m from './messages.tti'

export const devAddrEmptyCheck = attributes =>
  attributes === undefined ||
  (attributes instanceof Array &&
    (attributes.length === 0 || attributes.every(attribute => typeof attribute === 'string')))

export const devAddrFormatCheck = attributes =>
  attributes.length === 0 ||
  attributes.every(attribute => Boolean(devAddrPrefixRegExp.test(attribute)))

const validationSchema = Yup.object({
  is: Yup.object().when(
    [
      '_use_user_registration_defaults',
      '_use_password_requirements_defaults',
      '_use_uploads_defaults',
      '_use_user_rights_defaults',
    ],
    (
      [
        _use_user_registration_defaults,
        _use_password_requirements_defaults,
        _use_uploads_defaults,
        _use_user_rights_defaults,
      ],
      schema,
    ) => {
      if (
        _use_user_registration_defaults &&
        _use_password_requirements_defaults &&
        _use_uploads_defaults &&
        _use_user_rights_defaults
      ) {
        return Yup.object()
          .nullable()
          .transform(() => null)
      }
      return schema.shape({
        admin_rights: _use_user_rights_defaults
          ? Yup.object()
              .nullable()
              .transform(() => null)
          : Yup.object({
              all: Yup.boolean(),
            }),
        end_device_picture: Yup.lazy(() =>
          _use_uploads_defaults
            ? Yup.object()
                .nullable()
                .transform(() => null)
            : Yup.object({
                disable_upload: Yup.boolean(),
              }),
        ),
        profile_picture: Yup.lazy(() =>
          _use_uploads_defaults
            ? Yup.object()
                .nullable()
                .transform(() => null)
            : Yup.object({
                disable_upload: Yup.boolean(),
                use_gravatar: Yup.boolean(),
              }),
        ),
        user_registration: Yup.lazy(() => {
          if (_use_user_registration_defaults && _use_password_requirements_defaults) {
            return Yup.object()
              .nullable()
              .transform(() => null)
          }
          const passwordRequirementsSchema = Yup.object({
            max_length: Yup.number().min(0),
            min_length: Yup.number().min(0),
            min_digits: Yup.number().min(0),
            min_special: Yup.number().min(0),
            min_uppercase: Yup.number().min(0),
          })

          if (!_use_password_requirements_defaults && _use_user_registration_defaults) {
            return Yup.object({
              password_requirements: passwordRequirementsSchema,
              admin_approval: Yup.object()
                .nullable()
                .transform(() => null),
              contact_info_validation: Yup.object()
                .nullable()
                .transform(() => null),
              enabled: Yup.object()
                .nullable()
                .transform(() => null),
              invitation: Yup.object()
                .nullable()
                .transform(() => null),
            }).noUnknown()
          }

          return Yup.object({
            admin_approval: Yup.object({
              required: Yup.boolean(),
            }),
            contact_info_validation: Yup.object({
              required: Yup.boolean(),
            }),
            enabled: Yup.boolean(),
            invitation: Yup.object({
              required: Yup.boolean(),
              token_ttl: Yup.string().matches(
                /[\d,.]+(s|m|h)/,
                () => sharedMessages.validateRequired,
              ),
            }),
            password_requirements: _use_password_requirements_defaults
              ? Yup.object()
                  .nullable()
                  .transform(() => null)
              : passwordRequirementsSchema,
          })
        }),
        user_rights: Yup.lazy(() =>
          _use_user_rights_defaults
            ? Yup.object()
                .nullable()
                .transform(() => null)
            : Yup.object({
                create_applications: Yup.boolean(),
                create_organizations: Yup.boolean(),
                create_gateways: Yup.boolean(),
                create_clients: Yup.boolean(),
              }),
        ),
      })
    },
  ),
  ns: Yup.object().when(
    '_use_network_settings_defaults',
    ([_use_network_default_settings], schema) => {
      if (_use_network_default_settings) {
        return Yup.object()
          .nullable()
          .transform(() => null)
      }

      return schema.shape({
        cooldown_window: Yup.string().matches(
          /[\d,.]+(s|ms)/,
          () => sharedMessages.validateRequired,
        ),
        deduplication_window: Yup.string().matches(
          /[\d,.]+(s|ms)/,
          () => sharedMessages.validateRequired,
        ),
        dev_addr_prefixes: Yup.array()
          .test('has no empty string values', m.devAddrPrefixesEmptyValidation, devAddrEmptyCheck)
          .test('has correct format', m.devAddrPrefixesFormatValidation, devAddrFormatCheck),
      })
    },
  ),
  ui: Yup.lazy(val =>
    !val || val.branding_base_url === ''
      ? Yup.object()
          .nullable()
          .transform(() => null)
      : Yup.object({ branding_base_url: Yup.string() }).test(
          'has correct format',
          sharedMessages.validateUrl,
          val =>
            typeof val === 'object' &&
            typeof val.branding_base_url === 'string' &&
            (urlRegExp.test(val.branding_base_url) || hostnameRegExp.test(val.branding_base_url)),
        ),
  ),
})

export default validationSchema
