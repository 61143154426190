// Copyright © 2024 The Things Industries B.V.

import { defineMessages } from 'react-intl'

const m = defineMessages({
  addAlertReceiver: 'Add an alert receiver',
  noAlertReceivers: 'You have no alert receivers set up currently',
})

export default m
