// Copyright © 2021 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React from 'react'
import { useIntl } from 'react-intl'

import Icon from '@ttn-lw/components/icon'
import Link from '@ttn-lw/components/link'

import PropTypes from '@ttn-lw/lib/prop-types'
import sharedMessages from '@ttn-lw/lib/shared-messages'

import style from './deployment-tag.styl'

const DeploymentTag = ({ brandingText, clusterId, clusterPickerUrl }) => {
  const hasBranding = Boolean(clusterId || brandingText)

  const { formatMessage } = useIntl()

  if (!hasBranding) {
    return null
  }

  const inner = (
    <div className={style.branding} title={formatMessage(sharedMessages.clusterInformation)}>
      {Boolean(clusterId) && (
        <>
          <Icon className={style.brandingIcon} icon="cluster" nudgeUp />
          <span className={style.brandingCluster}>{clusterId}</span>
        </>
      )}
      <span className={style.brandingText}>{brandingText}</span>
    </div>
  )

  return Boolean(clusterPickerUrl) ? (
    <Link.Anchor href={clusterPickerUrl}>{inner}</Link.Anchor>
  ) : (
    inner
  )
}

DeploymentTag.propTypes = {
  /** The branding text of the deployment. */
  brandingText: PropTypes.string,
  /** The cluster ID of the deployment. */
  clusterId: PropTypes.string,
  /** The URL to the cluster picker. */
  clusterPickerUrl: PropTypes.string,
}

DeploymentTag.defaultProps = {
  brandingText: undefined,
  clusterId: undefined,
  clusterPickerUrl: undefined,
}

export default DeploymentTag
