// Copyright © 2023 The Things Industries B.V.

import axios from 'axios'

import { selectPluginTTSCloud } from '@ttn-lw/lib/selectors/env'

const subscriptionManagementUrl = selectPluginTTSCloud().subscription_management_url
const apiRoot = `${subscriptionManagementUrl}/api`

export default {
  subscriptionInfo: price_id => axios.get(`${apiRoot}/products/prices/${price_id}`),
}
