// Copyright © 2019 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useState, useCallback } from 'react'
import classnames from 'classnames'

import hamburgerMenuNormal from '@assets/misc/hamburger-menu-normal.svg'
import hamburgerMenuClose from '@assets/misc/hamburger-menu-close.svg'

import DeploymentTag from '@ttn-lw/components/deployment-tag'
import NavigationBar from '@ttn-lw/components/navigation/bar'
import ProfileDropdown from '@ttn-lw/components/profile-dropdown'
import MobileMenu from '@ttn-lw/components/mobile-menu'
import Input from '@ttn-lw/components/input'
import DisclaimerCycler from '@ttn-lw/components/disclaimer-cycler'
import Link from '@ttn-lw/components/link'

import PropTypes from '@ttn-lw/lib/prop-types'

import style from './header.styl'

const LogoLink = ({ safe, to, ...rest }) => {
  if (safe) {
    return <a href={to} {...rest} />
  }

  return <Link to={to} {...rest} />
}

LogoLink.propTypes = {
  safe: PropTypes.bool,
  to: PropTypes.string.isRequired,
}

LogoLink.defaultProps = {
  safe: false,
}

const Header = ({
  className,
  dropdownItems,
  navigationEntries,
  user,
  searchable,
  mobileDropdownItems,
  onLogout,
  onSearchRequest,
  brandingLogo,
  ttsLogo,
  brandingText,
  clusterId,
  clusterPickerUrl,
  slaApplies,
  slaInformationUrl,
  fairUsePolicyInformationUrl,
  supportPlanApplies,
  supportPlanInformationUrl,
  applicationSiteName,
  safe,
  nocMessage,
  ...rest
}) => {
  const isGuest = !Boolean(user)

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const handleMobileMenuClick = useCallback(() => {
    setMobileMenuOpen(!mobileMenuOpen)
  }, [mobileMenuOpen])

  const handleMobileMenuItemsClick = useCallback(() => {
    setMobileMenuOpen(false)
  }, [])

  const classNames = classnames(className, style.container, {
    [style.mobileMenuOpen]: mobileMenuOpen,
  })

  const hamburgerGraphic = mobileMenuOpen ? hamburgerMenuClose : hamburgerMenuNormal

  const deploymentInfoProps = {
    brandingText,
    clusterId,
    clusterPickerUrl,
  }

  const disclaimerProps = {
    slaApplies,
    slaInformationUrl,
    fairUsePolicyInformationUrl,
    supportPlanApplies,
    supportPlanInformationUrl,
  }

  return (
    <header {...rest} className={classNames}>
      <div className={style.bar}>
        <div className={style.left}>
          <LogoLink to="/" className={style.logoLink} safe={safe}>
            {Boolean(brandingLogo) && (
              <div className={style.brandLogo}>
                <img src={brandingLogo} alt={`${applicationSiteName} Logo`} />
              </div>
            )}
            <div className={style.ttsLogo}>
              <img src={ttsLogo} alt="The Things Stack Logo" />
            </div>
          </LogoLink>
          {!isGuest && <NavigationBar className={style.navList}>{navigationEntries}</NavigationBar>}
        </div>
        {!isGuest && (
          <div className={style.right}>
            {searchable && <Input icon="search" onEnter={onSearchRequest} />}
            <div className={style.deployment}>
              <DeploymentTag {...deploymentInfoProps} />
              <DisclaimerCycler {...disclaimerProps} />
            </div>
            <ProfileDropdown
              className={style.profileDropdown}
              userName={user.name || user.ids.user_id}
              data-test-id="profile-dropdown"
              profilePicture={user.profile_picture}
            >
              {dropdownItems}
            </ProfileDropdown>
            <button onClick={handleMobileMenuClick} className={style.mobileMenuButton}>
              <div className={style.hamburger}>
                <img src={hamburgerGraphic} alt="Open Mobile Menu" />
              </div>
            </button>
          </div>
        )}
      </div>
      {mobileMenuOpen && (
        <MobileMenu
          className={style.mobileMenu}
          onItemsClick={handleMobileMenuItemsClick}
          onLogout={onLogout}
          user={user}
          bottomArea={
            <>
              <DeploymentTag {...deploymentInfoProps} />
              <DisclaimerCycler align="right" {...disclaimerProps} />
            </>
          }
        >
          {mobileDropdownItems}
        </MobileMenu>
      )}
    </header>
  )
}

Header.propTypes = {
  /** The application site name. */
  applicationSiteName: PropTypes.string.isRequired,
  /** The URL of the branding logo. */
  brandingLogo: PropTypes.string,
  /** The classname applied to the component. */
  className: PropTypes.string,
  /** The child node of the dropdown component. */
  dropdownItems: PropTypes.node,
  /** The URL for further information on the fair use policy. */
  fairUsePolicyInformationUrl: DisclaimerCycler.propTypes.fairUsePolicyInformationUrl,
  /** The child node of the mobile dropdown. */
  mobileDropdownItems: PropTypes.node,
  /** The Child node of the navigation bar. */
  navigationEntries: PropTypes.node,
  /** A handler for when the user used the search input. */
  onLogout: PropTypes.func,
  /** Handler of the search function. */
  onSearchRequest: PropTypes.func,
  /* A flag indicating whether the header has a search input. */
  searchable: PropTypes.bool,
  /** The type of SLA that applies on this deployment. */
  slaApplies: DisclaimerCycler.propTypes.slaApplies,
  /** The URL for further information on the SLA. */
  slaInformationUrl: DisclaimerCycler.propTypes.slaInformationUrl,
  /** The support plan that applies. */
  supportPlanApplies: DisclaimerCycler.propTypes.supportPlanApplies,
  /** The URL for further information on the fair use policy. */
  supportPlanInformationUrl: DisclaimerCycler.propTypes.supportPlanInformationUrl,
  /** The URL of the TTS logo. */
  ttsLogo: PropTypes.string.isRequired,
  /**
   * The User object, retrieved from the API. If it is `undefined`, then the
   * guest header is rendered.
   */
  user: PropTypes.user,
  ...DeploymentTag.propTypes,
}

Header.defaultProps = {
  brandingLogo: undefined,
  className: undefined,
  dropdownItems: undefined,
  fairUsePolicyInformationUrl: undefined,
  navigationEntries: undefined,
  mobileDropdownItems: null,
  onSearchRequest: () => null,
  onLogout: () => null,
  searchable: false,
  slaApplies: undefined,
  slaInformationUrl: undefined,
  supportPlanApplies: undefined,
  supportPlanInformationUrl: undefined,
  user: undefined,
  ...DeploymentTag.defaultProps,
}

export default Header
