// Copyright © 2024 The Things Industries B.V.

import tts from '@console/api/tts'

import createRequestLogic from '@ttn-lw/lib/store/logics/create-request-logic'

import * as ars from '@console/store/actions/alert-routing-server.tti'

const getArsConfigurationLogic = createRequestLogic({
  type: ars.GET_ARS_CONFIGURATION,
  process: async () => await tts.Ars.getConfiguration(),
})

export default [getArsConfigurationLogic]
