// Copyright © 2022 The Things Industries B.V.

import React from 'react'

import { useBreadcrumbs } from '@ttn-lw/components/breadcrumbs/context'
import Breadcrumb from '@ttn-lw/components/breadcrumbs/breadcrumb'
import PageTitle from '@ttn-lw/components/page-title'

import RequireRequest from '@ttn-lw/lib/components/require-request'

import TenantSettingsForm from '@console/containers/tenant-settings-form/index.tti'

import Require from '@console/lib/components/require'

import sharedMessages from '@ttn-lw/lib/shared-messages'

import { mayConfigureTenantSettings } from '@console/lib/feature-checks'

import { getIsConfiguration } from '@console/store/actions/identity-server'
import { getOwnTenant } from '@console/store/actions/tenant.tti'

const TenantSettings = () => {
  useBreadcrumbs(
    'admin-panel.tenant-settings',
    <Breadcrumb path={'/admin-panel/tenant-settings'} content={sharedMessages.tenantSettings} />,
  )

  return (
    <RequireRequest requestAction={[getIsConfiguration(), getOwnTenant('configuration,name')]}>
      <Require featureCheck={mayConfigureTenantSettings} otherwise={{ redirect: '/' }}>
        <PageTitle title={sharedMessages.tenantSettings} className="panel-title" noGrid />
        <TenantSettingsForm />
      </Require>
    </RequireRequest>
  )
}

export default TenantSettings
