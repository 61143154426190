// Copyright © 2023 The Things Industries B.V.

import React from 'react'
import { Col, Row } from 'react-grid-system'
import { useSelector } from 'react-redux'
import { defineMessages } from 'react-intl'

import Link from '@ttn-lw/components/link'
import ProgressBar from '@ttn-lw/components/progress-bar'

import Message from '@ttn-lw/lib/components/message'

import { selectRegistryTotals } from '@console/store/selectors/tenant.tti'
import { selectSubscriptionProduct } from '@console/store/selectors/subscription-management.tti'

import { calculatePercentage, getEntity } from '../utils.tti'
import style from '../network-information.styl'

const m = defineMessages({
  end_devicesCounted: '{count, plural, one {end device} other {end devices}}',
  applicationsCounted: '{count, plural, one {application} other {applications}}',
  gatewaysCounted: '{count, plural, one {gateway} other {gateways}}',
  usersCounted: '{count, plural, one {user} other {users}}',
  organizationsCounted: '{count, plural, one {organization} other {organizations}}',
  limit: 'Limit: {limitEntities, number}',
  progressBarMessage:
    'You are currently using <b>{percentage, number, percent} of your current device package</b>. Your subscription will be <b>automatically updated</b> to the next higher-level package if you surpass the limit. <Link>Learn more</Link>.',
  currentEndDevices: '{totalEndDevices, number} end devices',
  targetEndDevices: 'Package threshold: {endDevicesThreshold, number}',
  end_devicesLimitInfo:
    'The network is currently using <b>{percentage, number, percent} of its {count, plural, one {device} other {devices}} limit</b>.',
  applicationsLimitInfo:
    'The network is currently using <b>{percentage, number, percent} of its {count, plural, one {application} other {applications}} limit</b>.',
  gatewaysLimitInfo:
    'The network is currently using <b>{percentage, number, percent} of its {count, plural, one {gateway} other {gateways}} limit</b>.',
  usersLimitInfo:
    'The network is currently using <b>{percentage, number, percent} of its {count, plural, one {user} other {users}} limit</b>.',
  organizationsLimitInfo:
    'The network is currently using <b>{percentage, number, percent} of its {count, plural, one {organization} other {organizations}} limit</b>.',
  networkStats: 'Network stats',
})

const NetworkStats = () => {
  const registryTotals = useSelector(selectRegistryTotals)
  const currentEndDevices = registryTotals?.end_devices ?? 0
  const subscriptionInfo = useSelector(selectSubscriptionProduct)
  const isDiscovery = subscriptionInfo.product.symbol === 'DISCOVERY'
  const metadata = subscriptionInfo.price.metadata
  const tiers = !isDiscovery ? subscriptionInfo.price.tiers : []
  const packageThreshold = !isDiscovery
    ? tiers.find(tier => tier.up_to >= currentEndDevices).up_to
    : null

  const entityMessage = (
    <Message
      content={m.end_devicesCounted}
      values={{
        count: currentEndDevices,
      }}
    />
  )
  const targetMessage = (
    <Message
      content={m.targetEndDevices}
      values={{ endDevicesThreshold: packageThreshold }}
      component="div"
    />
  )
  const cloudDescription = (
    <Message
      className={style.progressBarMessage}
      content={m.progressBarMessage}
      values={{
        percentage: `${calculatePercentage(currentEndDevices, packageThreshold)}%`,
        Link: msg => (
          <Link primary to={'/'} target="_blank">
            {msg}
          </Link>
        ),
        b: msg => <b>{msg}</b>,
      }}
    />
  )

  return (
    <>
      <Message content={m.networkStats} component="h3" className="mt-cs-xxl mb-ls-s panel-title" />
      <Row>
        {isDiscovery ? (
          Object.keys(subscriptionInfo.price.metadata).map((key, i) => {
            if (key !== 'noc-access-extended') {
              const entity = getEntity(key)
              const current = parseInt(registryTotals?.[entity])
              const target = parseInt(metadata[key])
              return (
                <Col md={6} key={i}>
                  <ProgressBar
                    className="mb-cs-l"
                    current={current}
                    target={target}
                    headerTargetMessage={
                      <Message
                        content={m.limit}
                        values={{ limitEntities: target }}
                        component="div"
                      />
                    }
                    itemName={
                      <Message
                        content={m[`${entity}Counted`]}
                        values={{
                          count: parseInt(registryTotals[entity]),
                        }}
                      />
                    }
                    warn={calculatePercentage(current, target)}
                    showEstimation={false}
                    showStatus
                    showHeader
                  >
                    <Message
                      className={style.progressBarMessage}
                      content={m[`${entity}LimitInfo`]}
                      values={{
                        percentage: current / target,
                        count: current,
                        b: msg => <b>{msg}</b>,
                      }}
                    />
                  </ProgressBar>
                </Col>
              )
            }

            return null
          })
        ) : (
          <Col>
            <ProgressBar
              className="mb-cs-l"
              barClassName={style.progressBar}
              current={parseInt(currentEndDevices)}
              target={parseInt(packageThreshold)}
              headerTargetMessage={targetMessage}
              itemName={entityMessage}
              showEstimation={false}
              showHeader
              warn={calculatePercentage(parseInt(currentEndDevices), parseInt(packageThreshold))}
            >
              {cloudDescription}
            </ProgressBar>
          </Col>
        )}
      </Row>
    </>
  )
}

export default NetworkStats
