// Copyright © 2022 The Things Industries B.V.

import {
  GET_OWN_TENANT_SUCCESS,
  GET_OWN_TENANT,
  GET_REGISTRY_TOTALS_SUCCESS,
  GET_OWN_TENANT_FAILURE,
} from '@console/store/actions/tenant.tti'

const defaultState = {
  tenant: undefined,
  registry_totals: undefined,
}

const tenant = (state = defaultState, { type, payload }) => {
  switch (type) {
    case GET_OWN_TENANT:
      return {
        ...state,
        tenant: undefined,
      }
    case GET_OWN_TENANT_SUCCESS:
      return {
        ...state,
        tenant: payload,
      }
    case GET_OWN_TENANT_FAILURE:
      return {
        ...state,
        tenant: undefined,
      }
    case GET_REGISTRY_TOTALS_SUCCESS:
      return {
        ...state,
        registry_totals: payload,
      }
    default:
      return state
  }
}

export default tenant
