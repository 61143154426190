// Copyright © 2024 The Things Industries B.V.

import { createFetchingSelector } from '@ttn-lw/lib/store/selectors/fetching'
import { createErrorSelector } from '@ttn-lw/lib/store/selectors/error'

import { GET_ARS_CONFIGURATION_BASE } from '@console/store/actions/alert-routing-server.tti'

const selectArsStore = state => state.ars

export const selectArsConfiguration = state => selectArsStore(state).configuration
export const selectArsConfigurationFetching = createFetchingSelector(GET_ARS_CONFIGURATION_BASE)
export const selectArsConfigurationError = createErrorSelector(GET_ARS_CONFIGURATION_BASE)

export const selectArsRoutingConfiguration = state => selectArsConfiguration(state).routing || {}
export const selectArsRoutingEnabled = state =>
  selectArsRoutingConfiguration(state).enabled || false
