// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

export const countryCodes = {
  '+1': { prefix: '+1', shortName: 'US/CA', name: 'United States/Canada' },
  '+20': { prefix: '+20', shortName: 'EG', name: 'Egypt' },
  '+27': { prefix: '+27', shortName: 'ZA', name: 'South Africa' },
  '+30': { prefix: '+30', shortName: 'GR', name: 'Greece' },
  '+31': { prefix: '+31', shortName: 'NL', name: 'Netherlands' },
  '+32': { prefix: '+32', shortName: 'BE', name: 'Belgium' },
  '+33': { prefix: '+33', shortName: 'FR', name: 'France' },
  '+34': { prefix: '+34', shortName: 'ES', name: 'Spain' },
  '+36': { prefix: '+36', shortName: 'HU', name: 'Hungary' },
  '+39': { prefix: '+39', shortName: 'IT', name: 'Italy' },
  '+40': { prefix: '+40', shortName: 'RO', name: 'Romania' },
  '+41': { prefix: '+41', shortName: 'CH', name: 'Switzerland' },
  '+43': { prefix: '+43', shortName: 'AT', name: 'Austria' },
  '+44': { prefix: '+44', shortName: 'GB', name: 'United Kingdom' },
  '+45': { prefix: '+45', shortName: 'DK', name: 'Denmark' },
  '+46': { prefix: '+46', shortName: 'SE', name: 'Sweden' },
  '+47': { prefix: '+47', shortName: 'NO', name: 'Norway' },
  '+48': { prefix: '+48', shortName: 'PL', name: 'Poland' },
  '+49': { prefix: '+49', shortName: 'DE', name: 'Germany' },
  '+51': { prefix: '+51', shortName: 'PE', name: 'Peru' },
  '+52': { prefix: '+52', shortName: 'MX', name: 'Mexico' },
  '+53': { prefix: '+53', shortName: 'CU', name: 'Cuba' },
  '+54': { prefix: '+54', shortName: 'AR', name: 'Argentina' },
  '+55': { prefix: '+55', shortName: 'BR', name: 'Brazil' },
  '+56': { prefix: '+56', shortName: 'CL', name: 'Chile' },
  '+57': { prefix: '+57', shortName: 'CO', name: 'Colombia' },
  '+58': { prefix: '+58', shortName: 'VE', name: 'Venezuela' },
  '+60': { prefix: '+60', shortName: 'MY', name: 'Malaysia' },
  '+61': { prefix: '+61', shortName: 'AU', name: 'Australia' },
  '+62': { prefix: '+62', shortName: 'ID', name: 'Indonesia' },
  '+63': { prefix: '+63', shortName: 'PH', name: 'Philippines' },
  '+64': { prefix: '+64', shortName: 'NZ', name: 'New Zealand' },
  '+65': { prefix: '+65', shortName: 'SG', name: 'Singapore' },
  '+66': { prefix: '+66', shortName: 'TH', name: 'Thailand' },
  '+81': { prefix: '+81', shortName: 'JP', name: 'Japan' },
  '+82': { prefix: '+82', shortName: 'KR', name: 'South Korea' },
  '+84': { prefix: '+84', shortName: 'VN', name: 'Vietnam' },
  '+86': { prefix: '+86', shortName: 'CN', name: 'China' },
  '+90': { prefix: '+90', shortName: 'TR', name: 'Turkey' },
  '+91': { prefix: '+91', shortName: 'IN', name: 'India' },
  '+92': { prefix: '+92', shortName: 'PK', name: 'Pakistan' },
  '+93': { prefix: '+93', shortName: 'AF', name: 'Afghanistan' },
  '+94': { prefix: '+94', shortName: 'LK', name: 'Sri Lanka' },
  '+95': { prefix: '+95', shortName: 'MM', name: 'Myanmar' },
  '+98': { prefix: '+98', shortName: 'IR', name: 'Iran' },
  '+212': { prefix: '+212', shortName: 'MA', name: 'Morocco' },
  '+213': { prefix: '+213', shortName: 'DZ', name: 'Algeria' },
  '+216': { prefix: '+216', shortName: 'TN', name: 'Tunisia' },
  '+218': { prefix: '+218', shortName: 'LY', name: 'Libya' },
  '+220': { prefix: '+220', shortName: 'GM', name: 'Gambia' },
  '+221': { prefix: '+221', shortName: 'SN', name: 'Senegal' },
  '+222': { prefix: '+222', shortName: 'MR', name: 'Mauritania' },
  '+223': { prefix: '+223', shortName: 'ML', name: 'Mali' },
  '+224': { prefix: '+224', shortName: 'GN', name: 'Guinea' },
  '+225': { prefix: '+225', shortName: 'CI', name: 'Ivory Coast' },
  '+226': { prefix: '+226', shortName: 'BF', name: 'Burkina Faso' },
  '+227': { prefix: '+227', shortName: 'NE', name: 'Niger' },
  '+228': { prefix: '+228', shortName: 'TG', name: 'Togo' },
  '+229': { prefix: '+229', shortName: 'BJ', name: 'Benin' },
  '+230': { prefix: '+230', shortName: 'MU', name: 'Mauritius' },
  '+231': { prefix: '+231', shortName: 'LR', name: 'Liberia' },
  '+232': { prefix: '+232', shortName: 'SL', name: 'Sierra Leone' },
  '+233': { prefix: '+233', shortName: 'GH', name: 'Ghana' },
  '+234': { prefix: '+234', shortName: 'NG', name: 'Nigeria' },
  '+235': { prefix: '+235', shortName: 'TD', name: 'Chad' },
  '+236': { prefix: '+236', shortName: 'CF', name: 'Central African Republic' },
  '+237': { prefix: '+237', shortName: 'CM', name: 'Cameroon' },
  '+238': { prefix: '+238', shortName: 'CV', name: 'Cape Verde' },
  '+239': { prefix: '+239', shortName: 'ST', name: 'Sao Tome and Principe' },
  '+240': { prefix: '+240', shortName: 'GQ', name: 'Equatorial Guinea' },
  '+241': { prefix: '+241', shortName: 'GA', name: 'Gabon' },
  '+242': { prefix: '+242', shortName: 'CG', name: 'Republic of the Congo' },
  '+243': { prefix: '+243', shortName: 'CD', name: 'Democratic Republic of the Congo' },
  '+244': { prefix: '+244', shortName: 'AO', name: 'Angola' },
  '+245': { prefix: '+245', shortName: 'GW', name: 'Guinea-Bissau' },
  '+246': { prefix: '+246', shortName: 'IO', name: 'British Indian Ocean Territory' },
  '+247': { prefix: '+247', shortName: 'AC', name: 'Ascension Island' },
  '+248': { prefix: '+248', shortName: 'SC', name: 'Seychelles' },
  '+249': { prefix: '+249', shortName: 'SD', name: 'Sudan' },
  '+250': { prefix: '+250', shortName: 'RW', name: 'Rwanda' },
  '+251': { prefix: '+251', shortName: 'ET', name: 'Ethiopia' },
  '+252': { prefix: '+252', shortName: 'SO', name: 'Somalia' },
  '+253': { prefix: '+253', shortName: 'DJ', name: 'Djibouti' },
  '+254': { prefix: '+254', shortName: 'KE', name: 'Kenya' },
  '+255': { prefix: '+255', shortName: 'TZ', name: 'Tanzania' },
  '+256': { prefix: '+256', shortName: 'UG', name: 'Uganda' },
  '+257': { prefix: '+257', shortName: 'BI', name: 'Burundi' },
  '+258': { prefix: '+258', shortName: 'MZ', name: 'Mozambique' },
  '+260': { prefix: '+260', shortName: 'ZM', name: 'Zambia' },
  '+261': { prefix: '+261', shortName: 'MG', name: 'Madagascar' },
  '+262': { prefix: '+262', shortName: 'RE', name: 'Reunion' },
  '+263': { prefix: '+263', shortName: 'ZW', name: 'Zimbabwe' },
  '+264': { prefix: '+264', shortName: 'NA', name: 'Namibia' },
  '+265': { prefix: '+265', shortName: 'MW', name: 'Malawi' },
  '+266': { prefix: '+266', shortName: 'LS', name: 'Lesotho' },
  '+267': { prefix: '+267', shortName: 'BW', name: 'Botswana' },
  '+268': { prefix: '+268', shortName: 'SZ', name: 'Swaziland' },
  '+269': { prefix: '+269', shortName: 'KM', name: 'Comoros' },
  '+290': { prefix: '+290', shortName: 'SH', name: 'Saint Helena' },
  '+291': { prefix: '+291', shortName: 'ER', name: 'Eritrea' },
  '+297': { prefix: '+297', shortName: 'AW', name: 'Aruba' },
  '+298': { prefix: '+298', shortName: 'FO', name: 'Faroe Islands' },
  '+299': { prefix: '+299', shortName: 'GL', name: 'Greenland' },
  '+350': { prefix: '+350', shortName: 'GI', name: 'Gibraltar' },
  '+351': { prefix: '+351', shortName: 'PT', name: 'Portugal' },
  '+352': { prefix: '+352', shortName: 'LU', name: 'Luxembourg' },
  '+353': { prefix: '+353', shortName: 'IE', name: 'Ireland' },
  '+354': { prefix: '+354', shortName: 'IS', name: 'Iceland' },
  '+355': { prefix: '+355', shortName: 'AL', name: 'Albania' },
  '+356': { prefix: '+356', shortName: 'MT', name: 'Malta' },
  '+357': { prefix: '+357', shortName: 'CY', name: 'Cyprus' },
  '+358': { prefix: '+358', shortName: 'FI', name: 'Finland' },
  '+359': { prefix: '+359', shortName: 'BG', name: 'Bulgaria' },
  '+370': { prefix: '+370', shortName: 'LT', name: 'Lithuania' },
  '+371': { prefix: '+371', shortName: 'LV', name: 'Latvia' },
  '+372': { prefix: '+372', shortName: 'EE', name: 'Estonia' },
  '+373': { prefix: '+373', shortName: 'MD', name: 'Moldova' },
  '+374': { prefix: '+374', shortName: 'AM', name: 'Armenia' },
  '+375': { prefix: '+375', shortName: 'BY', name: 'Belarus' },
  '+376': { prefix: '+376', shortName: 'AD', name: 'Andorra' },
  '+377': { prefix: '+377', shortName: 'MC', name: 'Monaco' },
  '+378': { prefix: '+378', shortName: 'SM', name: 'San Marino' },
  '+380': { prefix: '+380', shortName: 'UA', name: 'Ukraine' },
  '+381': { prefix: '+381', shortName: 'RS', name: 'Serbia' },
  '+382': { prefix: '+382', shortName: 'ME', name: 'Montenegro' },
  '+385': { prefix: '+385', shortName: 'HR', name: 'Croatia' },
  '+386': { prefix: '+386', shortName: 'SI', name: 'Slovenia' },
  '+387': { prefix: '+387', shortName: 'BA', name: 'Bosnia and Herzegovina' },
  '+389': { prefix: '+389', shortName: 'MK', name: 'North Macedonia' },
  '+420': { prefix: '+420', shortName: 'CZ', name: 'Czech Republic' },
  '+421': { prefix: '+421', shortName: 'SK', name: 'Slovakia' },
  '+423': { prefix: '+423', shortName: 'LI', name: 'Liechtenstein' },
  '+500': { prefix: '+500', shortName: 'FK', name: 'Falkland Islands' },
  '+501': { prefix: '+501', shortName: 'BZ', name: 'Belize' },
  '+502': { prefix: '+502', shortName: 'GT', name: 'Guatemala' },
  '+503': { prefix: '+503', shortName: 'SV', name: 'El Salvador' },
  '+504': { prefix: '+504', shortName: 'HN', name: 'Honduras' },
  '+505': { prefix: '+505', shortName: 'NI', name: 'Nicaragua' },
  '+506': { prefix: '+506', shortName: 'CR', name: 'Costa Rica' },
  '+507': { prefix: '+507', shortName: 'PA', name: 'Panama' },
  '+508': { prefix: '+508', shortName: 'PM', name: 'Saint Pierre and Miquelon' },
  '+509': { prefix: '+509', shortName: 'HT', name: 'Haiti' },
  '+590': { prefix: '+590', shortName: 'GP', name: 'Guadeloupe' },
  '+591': { prefix: '+591', shortName: 'BO', name: 'Bolivia' },
  '+592': { prefix: '+592', shortName: 'GY', name: 'Guyana' },
  '+593': { prefix: '+593', shortName: 'EC', name: 'Ecuador' },
  '+594': { prefix: '+594', shortName: 'GF', name: 'French Guiana' },
  '+595': { prefix: '+595', shortName: 'PY', name: 'Paraguay' },
  '+596': { prefix: '+596', shortName: 'MQ', name: 'Martinique' },
  '+597': { prefix: '+597', shortName: 'SR', name: 'Suriname' },
  '+598': { prefix: '+598', shortName: 'UY', name: 'Uruguay' },
  '+599': { prefix: '+599', shortName: 'AN', name: 'Netherlands Antilles' },
  '+670': { prefix: '+670', shortName: 'TL', name: 'Timor-Leste' },
  '+672': { prefix: '+672', shortName: 'NF', name: 'Norfolk Island' },
  '+673': { prefix: '+673', shortName: 'BN', name: 'Brunei' },
  '+674': { prefix: '+674', shortName: 'NR', name: 'Nauru' },
  '+675': { prefix: '+675', shortName: 'PG', name: 'Papua New Guinea' },
  '+676': { prefix: '+676', shortName: 'TO', name: 'Tonga' },
  '+677': { prefix: '+677', shortName: 'SB', name: 'Solomon Islands' },
  '+678': { prefix: '+678', shortName: 'VU', name: 'Vanuatu' },
  '+679': { prefix: '+679', shortName: 'FJ', name: 'Fiji' },
  '+680': { prefix: '+680', shortName: 'PW', name: 'Palau' },
  '+681': { prefix: '+681', shortName: 'WF', name: 'Wallis and Futuna' },
  '+682': { prefix: '+682', shortName: 'CK', name: 'Cook Islands' },
  '+683': { prefix: '+683', shortName: 'NU', name: 'Niue' },
  '+685': { prefix: '+685', shortName: 'WS', name: 'Samoa' },
  '+686': { prefix: '+686', shortName: 'KI', name: 'Kiribati' },
  '+687': { prefix: '+687', shortName: 'NC', name: 'New Caledonia' },
  '+688': { prefix: '+688', shortName: 'TV', name: 'Tuvalu' },
  '+689': { prefix: '+689', shortName: 'PF', name: 'French Polynesia' },
  '+690': { prefix: '+690', shortName: 'TK', name: 'Tokelau' },
  '+691': { prefix: '+691', shortName: 'FM', name: 'Micronesia' },
  '+692': { prefix: '+692', shortName: 'MH', name: 'Marshall Islands' },
  '+800': { prefix: '+800', shortName: '', name: 'International Freephone Service' },
  '+808': { prefix: '+808', shortName: '', name: 'International Shared Cost Service' },
  '+850': { prefix: '+850', shortName: 'KP', name: 'North Korea' },
  '+852': { prefix: '+852', shortName: 'HK', name: 'Hong Kong' },
  '+853': { prefix: '+853', shortName: 'MO', name: 'Macau' },
  '+855': { prefix: '+855', shortName: 'KH', name: 'Cambodia' },
  '+856': { prefix: '+856', shortName: 'LA', name: 'Laos' },
  '+870': { prefix: '+870', shortName: '', name: 'Inmarsat SNAC' },
  '+878': { prefix: '+878', shortName: '', name: 'Universal Personal Telecommunications (UPT)' },
  '+880': { prefix: '+880', shortName: 'BD', name: 'Bangladesh' },
  '+881': { prefix: '+881', shortName: '', name: 'Global Mobile Satellite System (GMSS)' },
  '+882': { prefix: '+882', shortName: '', name: 'International Networks' },
  '+883': { prefix: '+883', shortName: '', name: 'International VoIP' },
  '+886': { prefix: '+886', shortName: 'TW', name: 'Taiwan' },
  '+888': { prefix: '+888', shortName: '', name: 'Telecommunications for Disaster Relief by OCHA' },
  '+960': { prefix: '+960', shortName: 'MV', name: 'Maldives' },
  '+961': { prefix: '+961', shortName: 'LB', name: 'Lebanon' },
  '+962': { prefix: '+962', shortName: 'JO', name: 'Jordan' },
  '+963': { prefix: '+963', shortName: 'SY', name: 'Syria' },
  '+964': { prefix: '+964', shortName: 'IQ', name: 'Iraq' },
  '+965': { prefix: '+965', shortName: 'KW', name: 'Kuwait' },
  '+966': { prefix: '+966', shortName: 'SA', name: 'Saudi Arabia' },
  '+967': { prefix: '+967', shortName: 'YE', name: 'Yemen' },
  '+968': { prefix: '+968', shortName: 'OM', name: 'Oman' },
  '+970': { prefix: '+970', shortName: 'PS', name: 'Palestine' },
  '+971': { prefix: '+971', shortName: 'AE', name: 'United Arab Emirates' },
  '+972': { prefix: '+972', shortName: 'IL', name: 'Israel' },
  '+973': { prefix: '+973', shortName: 'BH', name: 'Bahrain' },
  '+974': { prefix: '+974', shortName: 'QA', name: 'Qatar' },
  '+975': { prefix: '+975', shortName: 'BT', name: 'Bhutan' },
  '+976': { prefix: '+976', shortName: 'MN', name: 'Mongolia' },
  '+977': { prefix: '+977', shortName: 'NP', name: 'Nepal' },
  '+992': { prefix: '+992', shortName: 'TJ', name: 'Tajikistan' },
  '+993': { prefix: '+993', shortName: 'TM', name: 'Turkmenistan' },
  '+994': { prefix: '+994', shortName: 'AZ', name: 'Azerbaijan' },
  '+995': { prefix: '+995', shortName: 'GE', name: 'Georgia' },
  '+996': { prefix: '+996', shortName: 'KG', name: 'Kyrgyzstan' },
  '+998': { prefix: '+998', shortName: 'UZ', name: 'Uzbekistan' },
}

export const separateNumber = (phoneNumber, length = 3, initialPrefix = '+31') => {
  if (length === 0) {
    return { prefix: initialPrefix, number: '' }
  }

  const regex = new RegExp(`^(\\+\\d{${length}})(\\d*)$`)
  const match = phoneNumber.match(regex)

  if (match) {
    const prefix = match[1]
    const number = match[2]
    const foundCountry = countryCodes[prefix]

    if (foundCountry) {
      return { prefix, number }
    }
  }

  // Recursive call with a shorter prefix length
  return separateNumber(phoneNumber, length - 1)
}
