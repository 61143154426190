// Copyright © 2022 The Things Industries B.V.

import createRequestActions from '@ttn-lw/lib/store/actions/create-request-actions'

export const GET_OWN_TENANT_BASE = 'GET_OWN_TENANT'
export const [
  { request: GET_OWN_TENANT, success: GET_OWN_TENANT_SUCCESS, failure: GET_OWN_TENANT_FAILURE },
  { request: getOwnTenant, success: getOwnTenantSuccess, failure: getOwnTenantFailure },
] = createRequestActions(
  GET_OWN_TENANT_BASE,
  () => ({}),
  (selector, options) => ({ selector, options }),
)

export const UPDATE_OWN_TENANT_BASE = 'UPDATE_OWN_TENANT'
export const [
  {
    request: UPDATE_OWN_TENANT,
    success: UPDATE_OWN_TENANT_SUCCESS,
    failure: UPDATE_OWN_TENANT_FAILURE,
  },
  { request: updateOwnTenant, success: updateOwnTenantSuccess, failure: updateOwnTenantFailure },
] = createRequestActions(UPDATE_OWN_TENANT_BASE, patch => ({ patch }))

export const GET_REGISTRY_TOTALS_BASE = 'GET_REGISTRY_TOTALS'
export const [
  {
    request: GET_REGISTRY_TOTALS,
    success: GET_REGISTRY_TOTALS_SUCCESS,
    failure: GET_REGISTRY_TOTALS_FAILURE,
  },
  {
    request: getRegistryTotals,
    success: getRegistryTotalsSuccess,
    failure: getRegistryTotalsFailure,
  },
] = createRequestActions(
  GET_REGISTRY_TOTALS_BASE,
  () => ({}),
  selector => ({ selector }),
)
