// Copyright © 2024 The Things Industries B.V.

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { cloneDeep, difference, isEqual, omit } from 'lodash'

import Form from '@ttn-lw/components/form'
import SubmitButton from '@ttn-lw/components/submit-button'
import SubmitBar from '@ttn-lw/components/submit-bar'
import Button from '@ttn-lw/components/button'
import toast from '@ttn-lw/components/toast'
import Prompt from '@ttn-lw/components/prompt'

import Message from '@ttn-lw/lib/components/message'

import AlertReceiver from '@console/containers/alert-receiver/index.tti'
import validationSchema, {
  RECEIVER_TYPE,
} from '@console/containers/alert-receivers-form/validation-schema.tti'

import errorMessages from '@ttn-lw/lib/errors/error-messages'
import sharedMessages from '@ttn-lw/lib/shared-messages'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'
import diff from '@ttn-lw/lib/diff'

import {
  createAlertReceiver,
  deleteAlertReceiver,
  updateAlertReceiver,
} from '@console/store/actions/alert-notification.tti'

import { selectAlertReceiversStore } from '@console/store/selectors/alert-notification.tti'

import m from './messages.tti'

const emptyReceiver = {
  ids: {
    receiver_id: '',
  },
  email: {
    recipient: '',
  },
  sms: {
    phone_number: '',
  },
  webhook: {
    url: '',
    headers: {},
  },
}

const AlertReceiversForm = () => {
  const alertReceivers = useSelector(selectAlertReceiversStore)
  const dispatch = useDispatch()
  const formikRef = useRef(null)
  const [error, setError] = useState(undefined)

  const initialValues = useMemo(
    () => ({
      receivers: Object.values(alertReceivers).map(receiver => ({
        ...emptyReceiver,
        ...receiver,
        _receiver_type: receiver.email
          ? RECEIVER_TYPE.EMAIL
          : receiver.webhook
            ? RECEIVER_TYPE.WEBHOOK
            : RECEIVER_TYPE.SMS,
      })),
    }),
    [alertReceivers],
  )

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(initialValues)
    }
  }, [initialValues])

  const handleDeleteReceivers = useCallback(
    async values => {
      const initialIds = initialValues.receivers.map(receiver => receiver.ids.receiver_id)
      const currentIds = values.receivers.map(r => r.ids.receiver_id)
      const diff = difference(initialIds, currentIds)

      await Promise.all(
        diff.map(receiverId => dispatch(attachPromise(deleteAlertReceiver(receiverId)))),
      )
    },
    [dispatch, initialValues],
  )

  const getCreateBody = useCallback(r => {
    const res = cloneDeep(r)
    if (res._receiver_type === RECEIVER_TYPE.EMAIL) {
      delete res.sms
      delete res.webhook
    } else if (res._receiver_type === RECEIVER_TYPE.SMS) {
      delete res.email
      delete res.webhook
    } else {
      delete res.email
      delete res.sms
    }
    return res
  }, [])

  const handleCreateEditReceivers = useCallback(
    async values => {
      const tasks = values.receivers.map(async receiver => {
        const receiverId = receiver.ids.receiver_id
        const initialReceiver = initialValues.receivers.find(r => r.ids.receiver_id === receiverId)
        if (initialReceiver) {
          let receiverDiff = diff(initialReceiver, receiver, {
            exclude: ['created_at', 'updated_at', '_receiver_type'],
            patchArraysItems: false,
            patchInFull: [],
          })
          if (!isEqual(receiverDiff, {})) {
            if ('webhook' in receiverDiff) {
              receiverDiff = {
                webhook: {
                  url: receiver.webhook.url,
                  headers: receiver.webhook.headers,
                  ...receiverDiff.webhook,
                },
              }
            }
            await dispatch(
              attachPromise(updateAlertReceiver(receiverId, receiverDiff, ['contact_method'])),
            )
          }
        } else {
          // Create new receiver if it does not exist.
          await dispatch(attachPromise(createAlertReceiver(getCreateBody(receiver))))
        }
      })
      await Promise.all(tasks)
    },
    [dispatch, getCreateBody, initialValues.receivers],
  )

  const handleAddReceiverClick = useCallback(async () => {
    await formikRef.current.setValues({
      receivers: [
        ...formikRef.current.values.receivers,
        {
          ...emptyReceiver,
          _receiver_type: RECEIVER_TYPE.EMAIL,
        },
      ],
    })
  }, [formikRef])

  const handleRemoveReceiverClick = useCallback(
    async index => {
      const values = formikRef.current.values.receivers.filter((_, idx) => idx !== index)
      await formikRef.current.setValues({ receivers: values })
    },
    [formikRef],
  )

  const handleSubmit = useCallback(
    async values => {
      try {
        setError(undefined)
        await handleDeleteReceivers(values)
        await handleCreateEditReceivers(values)
        toast({
          title: sharedMessages.success,
          message: sharedMessages.successMessage,
          type: toast.types.SUCCESS,
        })
      } catch (err) {
        setError(err)
        toast({
          title: errorMessages.error,
          message: errorMessages.failedToSaveChanges,
          type: toast.types.ERROR,
        })
      }
    },
    [handleCreateEditReceivers, handleDeleteReceivers],
  )

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      error={error}
      formikRef={formikRef}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          {values.receivers.length === 0 && <Message component="p" content={m.noAlertReceivers} />}
          {values.receivers.map((r, idx) => (
            <React.Fragment key={`${r.ids.receiver_id}${idx}`}>
              <AlertReceiver
                receiver={r}
                handleRemoveReceiver={handleRemoveReceiverClick}
                index={idx}
              />
              <hr className="mb-cs-xl mt-cs-xl" />
            </React.Fragment>
          ))}

          <div className="mt-cs-xl mb-cs-xl">
            <Button
              icon="add"
              name="add_alert_receiver"
              type="button"
              message={m.addAlertReceiver}
              onClick={handleAddReceiverClick}
            />
          </div>
          <SubmitBar>
            <Form.Submit component={SubmitButton} message={sharedMessages.saveChanges} />
          </SubmitBar>
          <Prompt
            when={
              !isEqual(
                values.receivers.map(p => omit(p, ['created_at', 'updated_at'])),
                initialValues.receivers.map(p => omit(p, ['created_at', 'updated_at'])),
              )
            }
            message={sharedMessages.leavePageWarning}
            modal={{
              title: sharedMessages.confirmNavigation,
            }}
          />
        </>
      )}
    </Form>
  )
}

export default AlertReceiversForm
