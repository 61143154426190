// Copyright © 2024 The Things Network Foundation, The Things Industries B.V.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//     http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'

import Input from '@ttn-lw/components/input'
import Select from '@ttn-lw/components/select'
import { separateNumber, countryCodes } from '@ttn-lw/components/phone-input/utils'

import PropTypes from '@ttn-lw/lib/prop-types'

const PhoneInput = ({
  readOnly,
  value,
  onChange,
  name,
  onBlur,
  placeholder,
  inputWidth,
  className,
  defaultCountry,
  error,
  warning,
  disabled,
  ...rest
}) => {
  const initialPrefix = useMemo(() => {
    const matchingCountry = Object.values(countryCodes).find(c => c.shortName === defaultCountry)

    return matchingCountry ? matchingCountry.prefix : ''
  }, [defaultCountry])

  const phoneNumber = useMemo(() => separateNumber(value, 3, initialPrefix), [initialPrefix, value])

  const options = useMemo(
    () =>
      Object.values(countryCodes).map(c => ({
        value: c.prefix,
        label: `${c.shortName} ${c.prefix}`,
      })),
    [],
  )

  const handleChangePrefix = useCallback(
    prefix => {
      onChange(`${prefix}${phoneNumber.number}`)
    },
    [onChange, phoneNumber.number],
  )

  const handleChangeNumber = useCallback(
    number => {
      onChange(`${phoneNumber.prefix}${number}`)
    },
    [onChange, phoneNumber.prefix],
  )

  return (
    <div data-test-id={'phone-input'} className={classnames(className, 'd-flex', 'gap-cs-xs')}>
      <Select
        data-test-id={`${name}-select`}
        name={`${name}-select`}
        id={`${name}-select`}
        options={options}
        value={phoneNumber.prefix}
        onChange={handleChangePrefix}
        inputWidth="xs"
        readOnly={readOnly}
        error={error}
        warning={warning}
        onBlur={onBlur}
        disabled={disabled}
        {...rest}
      />
      <Input
        data-test-id={`${name}-input`}
        className="flex-grow"
        type="number"
        name={`${name}-input`}
        id={`${name}-input`}
        value={phoneNumber.number}
        onChange={handleChangeNumber}
        inputWidth={inputWidth}
        placeholder={placeholder}
        readOnly={readOnly}
        error={error}
        warning={warning}
        onBlur={onBlur}
        disabled={disabled}
        {...rest}
      />
    </div>
  )
}

PhoneInput.propTypes = {
  className: PropTypes.string,
  defaultCountry: PropTypes.oneOf(Object.values(countryCodes).map(c => c.shortName)),
  disabled: PropTypes.bool,
  error: PropTypes.bool,
  inputWidth: PropTypes.inputWidth,
  name: PropTypes.string.isRequired,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  placeholder: PropTypes.message,
  readOnly: PropTypes.bool,
  value: PropTypes.string,
  warning: PropTypes.bool,
}

PhoneInput.defaultProps = {
  className: undefined,
  value: undefined,
  readOnly: false,
  onBlur: () => null,
  placeholder: '',
  onChange: () => null,
  inputWidth: 'full',
  defaultCountry: 'NL',
  error: false,
  warning: false,
  disabled: false,
}

export default PhoneInput
