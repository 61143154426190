// Copyright © 2024 The Things Industries B.V.

import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { difference, isEqual, omit } from 'lodash'

import Form from '@ttn-lw/components/form'
import SubmitButton from '@ttn-lw/components/submit-button'
import SubmitBar from '@ttn-lw/components/submit-bar'
import Button from '@ttn-lw/components/button'
import toast from '@ttn-lw/components/toast'
import Prompt from '@ttn-lw/components/prompt'
import Select from '@ttn-lw/components/select'

import Message from '@ttn-lw/lib/components/message'

import AlertProfile from '@console/containers/alert-profile/index.tti'
import validationSchema from '@console/containers/alert-profiles-form/validation-schema.tti'

import sharedMessages from '@ttn-lw/lib/shared-messages'
import attachPromise from '@ttn-lw/lib/store/actions/attach-promise'
import errorMessages from '@ttn-lw/lib/errors/error-messages'
import diff from '@ttn-lw/lib/diff'

import {
  createAlertProfile,
  deleteAlertProfile,
  updateAlertProfile,
} from '@console/store/actions/alert-notification.tti'

import { selectAlertProfilesStore } from '@console/store/selectors/alert-notification.tti'

import m from './messages.tti'

const emptyProfile = {
  ids: {
    profile_id: '',
  },
  is_default: false,
  receivers_ids: [{ receiver_id: '' }],
}

const AlertProfilesForm = () => {
  const alertProfiles = useSelector(selectAlertProfilesStore)
  const dispatch = useDispatch()
  const formikRef = useRef(null)
  const [error, setError] = useState(undefined)

  const initialValues = useMemo(() => {
    const defaultProfileIdx = Object.values(alertProfiles).findIndex(p => p.is_default)
    return {
      profiles: Object.values(alertProfiles).map(profile => ({
        ...emptyProfile,
        ...profile,
      })),
      _default_profile: defaultProfileIdx,
    }
  }, [alertProfiles])

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.setValues(initialValues)
    }
  }, [initialValues])

  const handleDeleteProfiles = useCallback(
    async values => {
      const initialIds = initialValues.profiles.map(profile => profile.ids.profile_id)
      const currentIds = values.profiles.map(r => r.ids.profile_id)
      const diff = difference(initialIds, currentIds)

      await Promise.all(
        diff.map(profileId => dispatch(attachPromise(deleteAlertProfile(profileId)))),
      )
    },
    [dispatch, initialValues],
  )

  const handleCreateEditProfiles = useCallback(
    async values => {
      const tasks = values.profiles.map(async profile => {
        const profileId = profile.ids.profile_id
        const initialProfile = initialValues.profiles.find(p => p.ids.profile_id === profileId)
        if (initialProfile) {
          const profileDiff = diff(initialProfile, profile, {
            exclude: ['created_at', 'updated_at'],
            patchArraysItems: false,
            patchInFull: [],
          })

          if (!isEqual(profileDiff, {})) {
            await dispatch(attachPromise(updateAlertProfile(profileId, profileDiff)))
          }
        } else {
          // Create new profile if it does not exist.
          await dispatch(attachPromise(createAlertProfile(profile)))
          profile.created_at = new Date().toISOString()
        }
      })
      await Promise.all(tasks)
    },
    [dispatch, initialValues.profiles],
  )

  const handleAddProfileClick = useCallback(async () => {
    await formikRef.current.setValues(oldValues => ({
      ...oldValues,
      profiles: [...formikRef.current.values.profiles, emptyProfile],
    }))
  }, [formikRef])

  const handleRemoveProfileClick = useCallback(async index => {
    const { values, setValues } = formikRef.current

    await setValues({
      profiles: values.profiles.filter((_, idx) => idx !== index),
      _default_profile:
        values._default_profile === index
          ? -1
          : values._default_profile > index
            ? values._default_profile - 1
            : values._default_profile,
    })
  }, [])

  const handleSubmit = useCallback(
    async values => {
      try {
        setError(undefined)
        await handleCreateEditProfiles(values)
        await handleDeleteProfiles(values)
        toast({
          title: sharedMessages.success,
          message: sharedMessages.successMessage,
          type: toast.types.SUCCESS,
        })
      } catch (err) {
        setError(err)
        toast({
          title: errorMessages.error,
          message: errorMessages.failedToSaveChanges,
          type: toast.types.ERROR,
        })
      }
    },
    [handleCreateEditProfiles, handleDeleteProfiles],
  )

  const getProfileOptions = useCallback(
    profiles => [
      { value: -1, label: m.dontUseDefault },
      ...profiles
        .filter(p => Boolean(p.ids.profile_id))
        .map((p, idx) => ({
          value: idx,
          label: p.ids.profile_id,
        })),
    ],
    [],
  )

  const handleDefaultProfileChange = useCallback(async profileIdx => {
    await formikRef.current.setValues({
      profiles: formikRef.current.values.profiles.map((p, idx) => ({
        ...p,
        is_default: idx === profileIdx,
      })),
      _default_profile: profileIdx,
    })
  }, [])

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={initialValues}
      error={error}
      formikRef={formikRef}
      validationSchema={validationSchema}
    >
      {({ values }) => (
        <>
          {values.profiles.length === 0 && <Message component="p" content={m.noAlertProfiles} />}
          {values.profiles.map((p, idx) => (
            <React.Fragment key={`${p.ids.profile_id}${idx}`}>
              <AlertProfile
                profile={p}
                handleRemoveProfile={handleRemoveProfileClick}
                index={idx}
              />
              <hr className="mb-cs-xl mt-cs-xl" />
            </React.Fragment>
          ))}

          <div className="mt-cs-xl mb-cs-xl">
            <Button
              icon="add"
              name="add_alert_profile"
              type="button"
              message={m.addAlertProfile}
              onClick={handleAddProfileClick}
            />
          </div>

          {values.profiles.length !== 0 && (
            <>
              <hr className="mb-cs-xl mt-cs-xl" />

              <Form.Field
                title={m.defaultProfile}
                name="_default_profile"
                component={Select}
                onChange={handleDefaultProfileChange}
                options={getProfileOptions(values.profiles)}
                description={m.defaultProfileMessage}
              />
            </>
          )}

          <SubmitBar>
            <Form.Submit component={SubmitButton} message={sharedMessages.saveChanges} />
          </SubmitBar>
          <Prompt
            when={
              !isEqual(
                values.profiles.map(p => omit(p, ['created_at', 'updated_at'])),
                initialValues.profiles.map(p => omit(p, ['created_at', 'updated_at'])),
              )
            }
            message={sharedMessages.leavePageWarning.defaultMessage}
            modal={{
              title: sharedMessages.confirmNavigation,
            }}
          />
        </>
      )}
    </Form>
  )
}

export default AlertProfilesForm
